import Document from 'components/common/document';
import { HeadNew } from 'components/common/header';
import CryingPirates from 'components/Icons/CryingPirates';
import Icon from 'components/Icons/v2/Icon';
import { OrderHistory } from 'components/mypage';
import OrderHistoryItem from 'components/mypage/myOrder/HistoryItem';
import { Loading } from 'components/views';
import { conf } from 'conf/settings';
import { SESSION } from 'context';
import { check_session } from 'context/global/session';
import { get_manager_phone } from 'context/mypage';
import { useGetOrderHistories } from 'hooks/Query/MyPage';
import { memo, useEffect, useMemo, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { connect } from 'react-redux';
import styled from 'styled-components';

const MyOrder = memo(() => {
  const { inView, ref } = useInView({ delay: 1000 });
  const [pageNum, setPageNum] = useState(0);

  const { data: histories, isFetching, refetch } = useGetOrderHistories();

  useEffect(() => {
    setPageNum((prev) => prev + 1);
  }, [inView]);

  const handleClickMarketOrderPage = () => {
    window.location.href = `${conf.OPEN_MARKET_URL}/mypage/orders`;
  };

  const sliceHistories = useMemo(() => {
    if (histories) {
      return histories?.slice(0, pageNum * 20);
    }
  }, [histories, pageNum]);

  return (
    <Wrapper>
      <Document.Head title="주문내역 | 마이페이지" type="single" />
      <HeadNew
        label="수산시장 주문내역"
        rightIcon={<Icon color="#464C53" name="home-24_2" />}
        url="/홈"
      />
      {isFetching ? (
        <Loading />
      ) : (
        <>
          {sliceHistories?.length === 0 && (
            <EmptyBox>
              <CryingPirates fill="#C9CDD2" />
              <FlexBox>
                <EmptyTypo>주문내역이 없습니다</EmptyTypo>
                <EmptyTypo>다른 상품을 찾고 계신다면</EmptyTypo>
                <EmptyTypo>아래 버튼을 눌러주세요</EmptyTypo>
              </FlexBox>
              <StyledButton onClick={() => handleClickMarketOrderPage()}>
                산지마켓 주문내역 보러가기
              </StyledButton>
            </EmptyBox>
          )}

          <ContentsBox id="wtf">
            {sliceHistories?.map((history) => (
              <OrderHistory
                key={history.orderCode}
                history={history}
                refetch={refetch}
              />
            ))}
            <SliceViewRef ref={ref} />
          </ContentsBox>
        </>
      )}
    </Wrapper>
  );
});

const StyledButton = styled.button`
  position: fixed;
  bottom: 40px;
  background-color: white;
  border-radius: 4px;
  border: 1px solid var(--new-gray-gray-2, #e8ebed);
  width: 328px;
  padding: 13px 16px;
  cursor: pointer;
`;

const FlexBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const EmptyTypo = styled.div`
  color: var(--Gray-gray-4, #c9cdd2);
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: -0.3px;
`;

const EmptyBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  width: 100%;
  height: 100%;
  padding-top: 200px;
`;

const SliceViewRef = styled.div`
  position: absolute;
  bottom: 0;
`;

const ContentsBox = styled.div`
  position: relative;
  top: 56px;
  border-top: 1px solid var(--Gray-gray-3, #e8ebed);
  width: 100%;
  background-color: white;
`;

const Wrapper = styled.div`
  position: relative;
  background-color: white;
`;

export default connect(
  ({ [SESSION]: session }) => ({
    session,
  }),
  {
    check_session,
    get_manager_phone,
  }
)(MyOrder);

export { OrderHistoryItem };
