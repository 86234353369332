import client from './client';
import clientV3 from './clientV3';
import noInterceptors from './noInterceptors';

export const updateFishMarketCart = (params) => {
  return client.post('/www/cart', params);
};

// 장바구니에 담긴 해당 점포의 메뉴 수량 데이터
// ! 로그인 상태일 때만 호출하도록 할 것
export const getCartsCount = () => {
  return noInterceptors.get(`/auth`).then(async (res) => {
    const newData = {
      address: { building: null, street: null },
      business: null,
      email: null,
      identity: null,
      memberType: null,
      name: null,
      phone: null,
      profileImage: null,
      registerAt: null,
    };

    if (res.data === 'notLogin') {
      return newData;
    }

    const response = await clientV3.get('/www/carts/count');
    return response.data;
  });
};

export const createCarts = (params) =>
  clientV3.post('/www/carts', params).then((res) => res.data);

export const updateCarts = (product) => {
  return clientV3
    .put(`/www/carts/${product.id}`, product)
    .then((res) => res.data);
};

export const getCarts = () =>
  clientV3.get('/www/carts').then((res) => res.data);
