import Icon from 'components/Icons/v2/Icon';
import {
  AnimatePresence,
  motion,
  useDragControls,
} from 'framer-motion/dist/framer-motion';
import { memo, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';
import { GlobalStyle } from 'styles';

const SlideContent = memo(
  ({
    actions,
    bgColor,
    children,
    fullSlide,
    hideButton,
    infinityHeight,
    onClose,
  }) => {
    const dragConstraints = { bottom: 0, top: 0 };
    const dragElastic = { bottom: 0.8, top: 0.0 };
    const controls = useDragControls();

    const handleDragEnd = (_, info) => {
      if (info.velocity.y > 100) {
        onClose();
      }
    };

    useEffect(() => {
      const closeByEscape = (e) => {
        if (e.code === 'Escape') {
          onClose();
        }
      };
      document.addEventListener('keydown', closeByEscape);
      return () => {
        document.removeEventListener('keydown', closeByEscape);
      };
    }, []);
    return createPortal(
      <Wrap>
        <Backdrop
          animate={{
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            transition: {
              duration: 0.2,
            },
          }}
          exit={{
            backgroundColor: 'rgba(0, 0, 0, 0)',
          }}
          initial={{
            backgroundColor: 'rgba(0, 0, 0, 0)',
          }}
          onClick={onClose}
        />
        <StyledMotion
          animate="animate"
          bgColor={bgColor}
          exit="exit"
          fullSlide={fullSlide}
          initial="initial"
          variants={{
            animate: {
              transition: {
                bounce: 0,
                duration: 0.15,
              },
              y: 0,
            },
            exit: {
              transition: {
                duration: 0.25,
              },
              y: '100%',
            },
            initial: {
              y: fullSlide ? '0%' : '100%',
            },
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <GlobalStyle>
            {!hideButton && (
              <ModalHeaderWrapper>
                <StyledButtonMotion
                  drag="y"
                  dragConstraints={dragConstraints}
                  dragControls={controls}
                  dragElastic={dragElastic}
                  onDragEnd={handleDragEnd}
                />
                <Icon
                  name="close-24_2"
                  style={{ cursor: 'pointer' }}
                  onClick={onClose}
                />
              </ModalHeaderWrapper>
            )}
            <StyledDiv fullSlide={fullSlide} infinityHeight={infinityHeight}>
              {children}
            </StyledDiv>
            {actions}
          </GlobalStyle>
        </StyledMotion>
      </Wrap>,
      document.querySelector('#__portal')
    );
  }
);

const Slide = ({
  actions,
  bgColor,
  children,
  fullSlide,
  hideButton,
  infinityHeight,
  onClose,
  open,
}) => {
  const scrollYRef = useRef(0);

  useEffect(() => {
    if (open) {
      scrollYRef.current = window.scrollY;
      document.body.style.position = 'fixed';
      document.body.style.top = `-${scrollYRef.current}px`;
      document.body.style.width = '100%';
    } else {
      document.body.style.position = 'unset';
      document.body.style.top = 'unset';
      document.body.style.width = 'unset';
      window.scrollTo(0, scrollYRef.current);
    }
  }, [open]);

  return (
    <AnimatePresence>
      {open && (
        <SlideContent
          actions={actions}
          bgColor={bgColor}
          fullSlide={fullSlide}
          hideButton={hideButton}
          infinityHeight={infinityHeight}
          onClose={onClose}
        >
          {children}
        </SlideContent>
      )}
    </AnimatePresence>
  );
};

const ModalHeaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Backdrop = styled(motion.div)({
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  bottom: 0,
  left: 0,
  overflow: 'auto',
  position: 'fixed',
  right: 0,
  top: 0,
  zIndex: 3000,
});

const Wrap = styled.div`
  position: 'absolute';
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 3001;
  overflow: auto;
`;

const StyledDiv = styled.div`
  max-height: ${({ fullSlide, infinityHeight }) => {
    if (fullSlide) return 'calc(100vh - 56px)';
    if (!fullSlide && infinityHeight) return null;
    if (!fullSlide && !infinityHeight) return 'calc(80vh - 29px - 56px)';
  }};
  overflow: auto;
  ::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
`;

const StyledButtonMotion = styled(motion.div)`
  /* touch-action: none; */
  display: flex;
  position: relative;
  justify-content: center;
  padding-top: 12px;
  padding-bottom: 23px;
  width: 100%;
  z-index: 999;
`;

const StyledMotion = styled(motion.div)(({ bgColor, fullSlide }) => ({
  backgroundColor: bgColor || 'white',
  borderTopLeftRadius: fullSlide ? '0px' : '24px',
  borderTopRightRadius: fullSlide ? '0px' : '24px',
  bottom: 0,
  left: 0,
  marginLeft: 'auto',
  marginRight: 'auto',
  maxWidth: 420,
  overflow: 'hidden',
  position: 'fixed',
  right: 0,
  zIndex: 5000,
}));

export default Slide;
