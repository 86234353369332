import '../style.css';

import { useState } from 'react';

import { Types } from '../_context';

/**
 * 객체 비교 함수.
 * @param p
 * @param n
 * @returns {boolean}
 * @private
 */
function _equals(p, n) {
  if (p === n) {
    return true;
  }

  if (typeof p !== 'object' || typeof n !== 'object') {
    return false;
  }

  const p_props = Object.keys(p);
  const n_props = Object.keys(n);

  if (p_props.length !== n_props.length) {
    return false;
  }

  for (const x of p_props) {
    if (!n[x]) {
      return false;
    }
    if (!_equals(p[x], n[x])) {
      return false;
    }
  }

  return true;
}

const _ListOptionView = ({ onChange, onSelect, options, value }) => {
  const [master_options, slave_options] = options;
  const [master_sel, slave_sel] = value || [{}, {}];
  const [toggle, setToggle] = useState(master_sel.code);
  const [index] = ((code) =>
    master_options
      .map((m, idx) => [idx, m.code])
      .filter(([_, cd]) => cd === code)
      .map(([idx, _]) => idx))(master_sel.code);

  const _handleMasterSelect = (...value) => {
    const [v1, v2] = value;
    if (slave_options) {
      onChange([v1, v2]);
    } else {
      onSelect([v1]);
    }
  };

  const _handleSlaveSelect = (value) => {
    onSelect([master_sel, value]);
  };

  const _handleToggle = ({ code }) => {
    if (!_equals(toggle, code)) {
      setToggle(code);
    } else {
      setToggle({});
    }
  };

  const view_type = slave_options ? 'dual-step-category' : 'list-options';

  return (
    <div className={view_type}>
      <ul className="master" style={{ marginBottom: '96px' }}>
        {master_options.map((m, idx) => (
          <_ListOptionItem
            key={idx}
            activated={toggle}
            item={m}
            selected={master_sel.code}
            onActivate={_handleToggle}
            onSelect={(item) => _handleMasterSelect(item, slave_sel)}
          />
        ))}
      </ul>
      {slave_options && (
        <ul className="slave">
          {slave_options[index || 0].map((s, idx) => (
            <_ListOptionItem
              key={idx}
              item={s}
              selected={slave_sel.code}
              onSelect={(item) => _handleSlaveSelect(item)}
            />
          ))}
        </ul>
      )}
    </div>
  );
};

const _ListOptionItem = ({
  activated,
  item,
  onActivate,
  onSelect,
  selected,
}) => {
  const check_selection = (v) =>
    _equals(item.code, v) ||
    (item.sub || []).filter((sub) => _equals(sub.code, v)).length > 0;
  const _class_name = [
    check_selection(selected) ? 'on' : '',
    item.sub ? 'spread' : '',
  ]
    .filter((c) => c && c !== '')
    .join(' ');
  const _handle_select = (val) => (e) => {
    if (val.sub) {
      onActivate(val);
    } else {
      onSelect(val);
    }
  };

  return (
    <li className={_class_name} onClick={_handle_select(item)}>
      <span>{item.label}</span>
      {item.sub && check_selection(activated) && (
        <ul>
          {item.sub.map((s, idx) => (
            <li
              key={idx}
              className={_equals(s.code, selected) ? 'on' : ''}
              onClick={_handle_select(s)}
            >
              <span>{s.label}</span>
            </li>
          ))}
        </ul>
      )}
    </li>
  );
};

const _GridOptionView = ({ onSelect, options, value }) => {
  const [selected] = value;
  const _mark_selected = (item) => (item.code === selected.code ? 'on' : null);
  const _empties = ((diff) => (diff === 3 ? 0 : diff))(
    3 - (options.length % 3)
  );

  return (
    <div className="grid-category" style={{ overflowY: 'auto' }}>
      <ul style={{ marginBottom: '96px' }}>
        {options.map((item) => (
          <li
            key={item.code}
            className={['item', `cd-${item.code}`, _mark_selected(item)]
              .filter((s) => s != null)
              .join(' ')}
            onClick={() => onSelect([{ code: item.code, label: item.label }])}
          >
            <span>{item.label}</span>
          </li>
        ))}
        {[...Array(_empties)].map((_, idx) => (
          <li key={idx} className="item no-select" />
        ))}
      </ul>
    </div>
  );
};

const _OptionSection = ({ filter, onChange, onSelect, value }) => {
  if (!(filter.type || 'no-include').includes(Types.OPTION)) {
    return <></>;
  }

  const _value_handler = (handler, value) => {
    handler({ [filter.type]: value });
  };

  const [body] = window.document.getElementsByTagName('body');
  const tab = window.document.querySelector(
    '.search-filter > .filter-area > .option-tabs'
  );
  const mobile = window.innerWidth <= 575;
  const height =
    body.clientHeight -
    ((rect) => rect.top + rect.height)(tab.getBoundingClientRect()) -
    (mobile ? 55 : 0);
  switch (filter.data.mode) {
    case 'list':
      return (
        <div className="option-section" style={{ height }}>
          <_ListOptionView
            options={filter.data.options}
            value={value[filter.type]}
            onChange={(value) => _value_handler(onChange, value)}
            onSelect={(value) => _value_handler(onSelect, value)}
          />
        </div>
      );
    case 'grid':
      return (
        <div className="option-section" style={{ height }}>
          <_GridOptionView
            options={filter.data.options[0]}
            value={value[filter.type]}
            onChange={(value) => _value_handler(onChange, value)}
            onSelect={(value) => _value_handler(onSelect, value)}
          />
        </div>
      );
    default:
      return <></>;
  }
};

export default _OptionSection;
