import { ArrowRight } from 'components/Icons';
import dayjs from 'dayjs';
import downImg from 'img/icon_download.svg';
import { memo, useContext, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { getImageSrc } from 'utils';
import { moneyFormatter } from 'utils/Formatter';

import LoginContext, { View } from '../common/login/_context';

const CouponList = memo(({ coupon, saveCoupon, session }) => {
  const history = useHistory();
  const location = useLocation();

  const { action } = useContext(LoginContext);

  const handleDownloadClick = () => {
    if (session.identity !== null) {
      saveCoupon(coupon.id);
    } else {
      sessionStorage.setItem(
        'preLoginState',
        JSON.stringify({ couponId: coupon.id })
      );

      action.open(View.CHANNEL);
    }
  };

  const isPercentageType =
    coupon.discountRate && coupon.discountType === 'PERCENTAGE';
  const isFixedAmountType = coupon.discountType === 'FIXED_AMOUNT';

  const discountPrice = useMemo(() => {
    if (isPercentageType) return coupon.discountRate;
    if (isFixedAmountType) return `${moneyFormatter(coupon.discountPrice)}`;
    return '';
  }, [coupon]);

  const discountUnit = useMemo(() => {
    if (isPercentageType) return '% 할인';
    if (isFixedAmountType) return '원 할인';
    return '';
  }, [coupon]);

  const { product, type, item } = coupon;

  const onClickStoreDetail = () => {
    sessionStorage.setItem('mainPath', '/쿠폰/모든쿠폰');
    if (type === 'menu') {
      history.push(
        coupon.permalink ? `/menu/${coupon.permalink}/${product.id}` : '#',
        location.pathname.split('/')[1]
      );
    } else {
      history.push(
        coupon.permalink ? `/${coupon.permalink}?price=${item.baseId}` : '#',
        location.pathname.split('/')[1]
      );
    }
  };

  return (
    <CouponListStyle>
      <CouponBox>
        <Coupon>
          <ImageBox>
            <StyledImg
              alt=""
              src={`${getImageSrc(
                type === 'menu' ? product?.imageUrl : item?.imageUrl
              )}`}
            />
          </ImageBox>
          <div style={{ width: ' calc(100% - 106px - 16px)' }}>
            <DiscountPrice>
              <span>{discountPrice}</span>
              {discountUnit}
            </DiscountPrice>
            <MenuNameWrap onClick={onClickStoreDetail}>
              <MenuName>
                {type === 'menu'
                  ? product?.name?.replace('[★]', '')
                  : item?.name}
              </MenuName>
              <span>
                <ArrowRight fill="#464C53" size="13" />
              </span>
            </MenuNameWrap>
            <MarketName>
              {coupon.marketName} {coupon.storeName}
            </MarketName>
            <CouponPeriod>
              {`발급기간 : ${dayjs(coupon.issueEndDate).format(
                'YYYY.MM.DD HH:mm'
              )} 까지`}
            </CouponPeriod>
          </div>
        </Coupon>
        <CouponButton
          downloadState={coupon.useEndDate}
          onClick={(e) => {
            if (coupon.useEndDate) {
              e.stopPropagation();
            } else {
              handleDownloadClick();
            }
          }}
        >
          {coupon.useEndDate ? <>쿠폰 받기 완료</> : <>쿠폰 다운받기</>}
        </CouponButton>
      </CouponBox>
    </CouponListStyle>
  );
});

const CouponListStyle = styled.div``;

const CouponBox = styled.div`
  margin-bottom: 20px;
`;

const Coupon = styled.div`
  border: ${({ theme }) => `1px solid ${theme.gray200}`};
  border-radius: 5px;
  padding: 16px 12px;
  display: flex;
`;

const ImageBox = styled.div`
  height: 90px;
  width: 106px;
  border-radius: 5px;
  overflow: hidden;
  margin-right: 12px;
  min-width: 106px;
`;

const StyledImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const DiscountPrice = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.3px;
  color: ${({ theme }) => theme.red2};
  margin-bottom: 4px;

  & > span {
    font-size: 16px;
    font-weight: 600;
    /* font-family: 'Open Sans'; */
    margin-right: 1px;
    color: ${({ theme }) => theme.red2};
    line-height: 20px;
    letter-spacing: -0.3px;
  }
`;

const MenuNameWrap = styled.div`
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  max-width: 240px;

  cursor: pointer;

  & > span {
    width: 16px;
    height: 16px;
    text-align: center;
    margin-bottom: 2px;
  }
`;
const MenuName = styled.div`
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.3px;
  color: ${({ theme }) => theme.gray500};
  align-self: flex-start;

  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const MarketName = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: -0.3px;
  color: ${({ theme }) => theme.gray400};
`;

const CouponPeriod = styled.div`
  line-height: 14px;
  margin-top: 2px;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: -0.3px;
  color: ${({ theme }) => theme.gray400};
`;

const CouponButton = styled.div`
  border: ${({ theme }) => `1px solid ${theme.gray200}`};
  border-top: none;
  border-radius: 5px;
  padding: 10px 0;
  color: ${({ downloadState, theme }) =>
    downloadState ? theme.gray300 : theme.gray500};
  background: ${({ downloadState, theme }) =>
    downloadState ? theme.gray100 : theme.white200};
  text-align: center;
  line-height: 20px;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -0.3px;
  cursor: ${({ downloadState }) => (downloadState ? 'default' : 'pointer')};
`;

const CouponListProducer = () => {
  return (
    <CouponListProducerStyle className="coupon-list-producer">
      <div className="coupon-title">
        <h4>짐승용량 연어회</h4>
        <p className="sale-degree">10% 할인</p>
        <div className="store-name">
          <span>노량진수산시장</span>
          <strong>서림상회</strong>
          <i className="fas fa-chevron-right" />
        </div>
      </div>
      <figure className="coupon-img">
        <img alt="" src={downImg} />
      </figure>
    </CouponListProducerStyle>
  );
};

const CouponListProducerStyle = styled.div`
  display: flex;
  align-items: center;
  padding: 24px 16px;

  border: 1px solid #c9cdd2;
  border-radius: 5px;

  & * {
    padding: 0;
    margin: 0;
    color: #26282b;
    box-sizing: border-box;
  }
  & + .coupon-list-producer {
    margin-top: 16px;
  }

  & > .coupon-title {
    flex: 1;
    & > h4 {
      font-size: 16px;
      font-weight: 500;
    }
    & > p {
      font-size: 18px;
      font-weight: 500;
      color: #dd3333;
    }
    & > .store-name {
      margin-top: 1px;
      cursor: pointer;

      & > span {
        font-size: 12px;
      }
      & > strong {
        margin-left: 3px;
        font-size: 12px;
        font-weight: 500;
      }
      & > i {
        margin-left: 3px;
        color: #9fa4aa;
        font-size: 10px;
      }
    }
  }
  & > figure {
    display: flex;
    align-items: center;
    flex: 0 0 40px;

    & > img {
      width: 38px;
      height: 38px;
      padding: 7px;

      border-radius: 50%;
      background: #eaf3ff;

      cursor: pointer;
    }
  }
`;

export { CouponListProducer };
export default CouponList;
