import Icon from 'components/Icons/v2/Icon';
import EmptyDish from 'components/images/empty-dish.png';
import styled from 'styled-components';

const ProductBox = ({ product }) => {
  const {
    discountAmount,
    imageUrl,
    name,
    options,
    originPrice,
    priceName,
    quantity,
  } = product;

  const totalOptionPrice = options.reduce(
    (acc, cur) => acc + cur.price * cur.quantity,
    0
  );
  const totalProductPrice = (originPrice - discountAmount) * quantity;

  return (
    <Container>
      <InfoBox>
        <ProductNameBox>
          {product.discountType === 'COUPON' && (
            <StateTag>
              <Icon color="#EB5757" name="coupon-16_1" size={16} />
              쿠폰할인
            </StateTag>
          )}

          {product.discountType === 'SPECIAL_DISCOUNT' && (
            <StateTag>
              <Icon color="#EB5757" name="superprice_filled-16_1" size={16} />
              특가할인
            </StateTag>
          )}
          <ProductName>{name}</ProductName>
        </ProductNameBox>

        <OptionContainer>
          <OptionBox>
            <Dot />
            {priceName && (
              <>
                <OptionTypo>{priceName}</OptionTypo>
                <Divider />
              </>
            )}
            <OptionTypo>{quantity}개</OptionTypo>
            {discountAmount ? (
              <OptionTypo isLineThrough>
                ({originPrice.toLocaleString()}원)
              </OptionTypo>
            ) : null}
            <OptionTypo>({totalProductPrice.toLocaleString()}원)</OptionTypo>
          </OptionBox>

          {options.map((option, index) => (
            <OptionBox key={`option-list-${index}`}>
              <Dot />
              <OptionTypo>{option.name}</OptionTypo>
              <Divider />
              <OptionTypo>{option.quantity}개</OptionTypo>
              <OptionTypo>
                (
                {(
                  Number(option.price) * Number(option.quantity)
                ).toLocaleString()}
                원)
              </OptionTypo>
            </OptionBox>
          ))}
        </OptionContainer>
        <TotalPrice>
          {(totalOptionPrice + totalProductPrice).toLocaleString()}원
        </TotalPrice>
      </InfoBox>
      <ProductImage imageUrl={imageUrl ?? EmptyDish} />
    </Container>
  );
};

const TotalPrice = styled.span`
  overflow: hidden;
  color: var(--Gray-gray-11, #26282b);
  text-overflow: ellipsis;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.3px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
`;

const Divider = styled.span`
  width: 1px;
  height: 10px;
  background-color: #c9cdd2;
  margin: 0 2px;
`;

const OptionTypo = styled.div`
  color: var(--Gray-gray-6, #8a8d90);
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.3px;
  text-decoration-line: ${({ isLineThrough }) =>
    isLineThrough && `line-through`};
`;

const Dot = styled.div`
  width: 2px;
  height: 2px;
  background-color: var(--Gray-gray-5, #9fa4aa);
  border-radius: 50%;
`;

const OptionBox = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
`;

const OptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const ProductName = styled.div`
  overflow: hidden;
  color: var(--Gray-gray-11, #26282b);
  text-overflow: ellipsis;
  font-family: 'Noto Sans KR';
  font-size: 14px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  align-self: stretch;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: -0.3px;
`;

const ProductNameBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const StateTag = styled.div`
  display: flex;
  align-items: center;
  color: var(--Red-red-8, #eb5757);
  font-size: 11px;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: -0.3px;
  border-radius: 4px;
  background: var(--Red-red-1, #fff3f5);
  width: fit-content;
  padding: 0 6px;
`;

const ProductImage = styled.div`
  width: 120px;
  height: 78px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid var(--Gray-gray-1, #f7f8f9);
  background-image: ${({ imageUrl }) => `url(${imageUrl})`};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 12px;
`;

export default ProductBox;
