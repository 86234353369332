import * as content_api from 'api/content';
import * as home_api from 'api/home';
import * as price_api from 'api/price';
import { handleActions } from 'redux-actions';

import * as retail_api from '../api/retail';
import createApiRequest from './_support';

const SPECIALS = 'pirates-data.home/SPECIALS';
const STORES = 'pirates-data.home/STORES';
const RETAILS = 'pirates-data.home/RETAILS';
const REVIEWS = 'pirates-data.home/REVIEWS';
const PRICES = 'pirates-data.home/PRICES';
const SAVE_QUERY = 'pirates-data.home/SAVE_QUERY';
const TOGGLE_FAVORITE = 'pirates-data.specials/LIKE';
const TOGGLE_RETAIL_LIKE = `${RETAILS}_LIKE`;

const [load_home_specials, SPECIALS_OK, SPECIALS_FAIL] = createApiRequest(
  SPECIALS,
  home_api.load_specials
);
const [load_home_stores, STORES_OK, STORES_FAIL] = createApiRequest(
  STORES,
  (member) => home_api.get_recommends('store')
);
const [load_home_retails, RETAILS_OK, RETAILS_FAIL] = createApiRequest(
  RETAILS,
  (member) => home_api.get_recommends('shopping')
);
const [load_home_reviews, REVIEWS_OK, REVIEWS_FAIL] = createApiRequest(
  REVIEWS,
  home_api.get_reviews
);
const [load_home_prices, PRICES_OK, PRICES_FAIL] = createApiRequest(
  PRICES,
  price_api.get_recommend_price
);
const [save_query, SAVE_QUERY_OK, SAVE_QUERY_FAIL] = createApiRequest(
  SAVE_QUERY,
  (query) => ({ data: query })
);
const [toggle_favorite, TOGGLE_FAVORITE_OK, TOGGLE_FAVORITE_FAIL] =
  createApiRequest(TOGGLE_FAVORITE, content_api.toggle_content_like);
const [toggle_retail_like, TOGGLE_RETAIL_LIKE_OK, TOGGLE_RETAIL_LIKE_FAIL] =
  createApiRequest(TOGGLE_RETAIL_LIKE, retail_api.toggle_retail_like);

const state = {
  prices: [],
  query: {
    keyword: '',
  },
  retails: [],
  reviews: [],
  specials: [],
  stores: [],
};

const home = handleActions(
  {
    [PRICES]: (state) => ({ ...state }),
    [PRICES_OK]: (state, action) => ({
      ...state,
      prices: action.payload,
    }),

    [RETAILS]: (state) => ({ ...state }),
    [RETAILS_OK]: (state, action) => ({
      ...state,
      retails: action.payload,
    }),

    [REVIEWS]: (state) => ({ ...state }),
    [REVIEWS_OK]: (state, action) => ({
      ...state,
      reviews: action.payload,
    }),

    [SAVE_QUERY]: (state) => ({ ...state }),
    [SAVE_QUERY_OK]: (state, action) => ({
      ...state,
      query: action.payload,
    }),

    [SPECIALS]: (state) => ({ ...state }),
    [SPECIALS_OK]: (state, action) => ({
      ...state,
      specials: action.payload,
    }),

    [STORES]: (state) => ({ ...state }),
    [STORES_OK]: (state, action) => ({
      ...state,
      stores: action.payload,
    }),

    [TOGGLE_FAVORITE]: (state) => ({ ...state }),
    [TOGGLE_FAVORITE_OK]: (state, action) => ({
      ...state,
      specials: state.specials.map((special) => {
        if (special.id === parseInt(action.payload.targetCode)) {
          return {
            ...special,
            favorite: action.payload.doLike ? 'on' : 'off',
          };
        }
        return special;
      }),
    }),
    [TOGGLE_RETAIL_LIKE]: (state) => ({ ...state }),
    [TOGGLE_RETAIL_LIKE_OK]: (state, action) => {
      return {
        ...state,
        retails: state.retails.map((retail) => {
          if (retail.id === action.payload.targetCode) {
            return {
              ...retail,
              favorite: action.payload.doLike ? 'on' : 'off',
            };
          }
          return retail;
        }),
      };
    },
  },
  state
);

export {
  load_home_prices,
  load_home_retails,
  load_home_reviews,
  load_home_specials,
  load_home_stores,
  save_query,
  toggle_favorite,
  toggle_retail_like,
};

export default home;
