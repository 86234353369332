import client from 'api/client';

export const load_store_regions = () => client.get('/www/market/region');

export const load_restaurant_regions = () =>
  client.get('/www/restaurant/region');

export const load_item_codes = (options = ['all']) =>
  client.get('/www/market/product/group', {
    params: { options: options.join(',') },
  });

export const load_restaurant_features = () =>
  client.get('/www/restaurant/feature/group');

export const load_retail_groups = () => client.get('/www/retail/items/group');

export const load_wholesale_item_codes = () =>
  client.get('/www/wholesale/categories');

export const load_recommend_keywords = () => client.get('/www/search/list');
