/* eslint-disable no-console */
import client from 'api/client';

import clientV3 from './clientV3';
import { update_view_or_like } from './global';

let cancel;
const marketCancel = {};

/** ********************************************** */
/*                Store List APIs                */
/** ********************************************** */
const _default_query = {
  item: null,
  keyword: null,
  location: { latitude: null, longitude: null },
  next: { level: null, token: null },
  region: { place: null, zone: null },
  sortBy: null,
  user: null,
};

export const get_stores = async (target, query = { ..._default_query }) => {
  if (cancel) {
    cancel();
  }
  const _q = { ..._default_query, ...query };
  const params = {
    delivery: _q.delivery || null,

    itemGroup: _q.item,
    // deliveryCode: _q.deliveryCode,
    itemName: _q.itemName || null,

    keyword: _q.searchKeyword || null,

    latitude: _q.latitude,

    longitude: _q.longitude,

    marketName: _q.marketName || null,

    priceOrder: _q.next.priceOrder,
    // zoneCode: _q.region.zone,
    // marketCode: _q.region.place,
    // sort: _q.sortBy,
    // latitude: _q.location.latitude,
    // longitude: _q.location.longitude,
    randNum: _q.next.token,
    sort: _q.sort,
    sortLevel: _q.next.level,
    storeCodeList: _q.storeCodeList,
    test: 'TEst',
  };
  try {
    // const result = await client.get(`/www/${target}/list`, {
    //   params,
    // });

    // return result;

    const result = await client.get('/www/market/filter/list', {
      params,
    });
    return {
      ...result,
      data: result.data.content,
      totalElements: result.data.totalElements,
    };
  } catch (err) {
    console.log(err);
  }
};

export const get_store_recommends = (target, query = '') => {
  try {
    if (marketCancel.recommend) marketCancel.recommend();
    const _options = {
      ...{
        keyword: null,
        location: { latitude: null, longitude: null },
        region: { place: null, zone: null },
        store: null,
      },
      ...query,
    };
    const params = {
      params: {
        // keyword: _options.keyword,
        keyword: _options.searchKeyword,

        latitude: _options.location.latitude,

        longitude: _options.location.longitude,

        marketCode: _options.region.place,
        storeCode: _options.store,
        zoneCode: _options.region.zone,
      },
    };
    // if (target === 'market')
    //   params['cancelToken'] = new CancelToken(function executor(c) {
    //     marketCancel['recommend'] = c;
    //   });
    return client.get(`/www/${target}/recommend/`, params);
  } catch (err) {
    console.log(err);
  }
};

/** ********************************************** */
/*                Store View APIs                */
/** ********************************************** */
export const get_store = (store_link) =>
  client.get(`/www/market/store/${encodeURI(store_link)}`);

export const get_store_menus = (code) =>
  client.get(`/www/market/store/${code}/menu`);

export const get_store_prices = (code, updated) =>
  client.get(`/www/market/store/${code}/price/${updated}`);

export const get_store_price_history = ({ item, store, updated }) =>
  client.get(`/www/market/store/${store}/prices/last`, {
    params: { itemCode: item, lastDate: updated },
  });

export const get_store_article = (code) =>
  client.get(`/www/market/store/article/${code}`);

export const get_store_location = (code) =>
  client.get(`/www/market/store/${code}/location`);

export const get_store_recommend_reviews = (code) =>
  client.get(`/www/market/store/${code}/comments/recommend`);

export const update_store_view = (item, contentsType) =>
  update_view_or_like(item, {
    contentsType,
    doLike: false,
    type: 'view',
  });

export const toggle_store_like = (item, contentsType) =>
  update_view_or_like(item, { contentsType, type: 'like' });

export const get_stores_recent = (query) =>
  client.get('/www/store/recent', { params: query });

export const update_store_share = (params) =>
  client.put('/www/market/store/share-count', params);

export const update_store_call = (params) =>
  client.put('/www/market/store/call-count', params);

export const getShippingMethod = (storeCode, paymentPageId) =>
  client.get(
    `/www/stores/${storeCode}/order-type${
      paymentPageId ? `?paymentPageId=${paymentPageId}` : ''
    }`
  );

export const getRecommendStores = (params) =>
  client.get('/www/market/recommend', { params }).then((res) => res.data);

/** ********************************************** */
/*                new store api                  */
/** ********************************************** */
export const getStoreDetail = (storeLink) =>
  client
    .get(`/www/market/store/${encodeURI(storeLink)}`)
    .then((res) => res.data);

export const getRecentVisited = (query) =>
  client.get('/www/store/recent', { params: query }).then((res) => res.data);

export const getStoreRecommendReviews = (code) =>
  client
    .get(`/www/market/store/${code}/comments/recommend`)
    .then((res) => res.data);

export const getStoreTourArticle = (code) =>
  client.get(`/www/market/store/article/${code}`).then((res) => res.data);

export const getStoreLocation = (code) =>
  client.get(`/www/market/store/${code}/location`).then((res) => res.data);

export const getStoreReviews = ({ next, storeCode }) => {
  return client
    .get(`/www/comment/store/${storeCode}/comments`, {
      params: { sortNum: next ?? null, type: 1 },
    })
    .then((res) => res.data);
};

export const getStorePrices = (code, updated) =>
  client
    .get(`/www/market/store/${code}/price/${updated}`)
    .then((res) => res.data);

/** ********************************************** */
/*                메뉴 개편 이후 API               */
/** ********************************************** */
export const getKeywords = (storeCode) =>
  clientV3
    .get(`/www/stores/${storeCode}/keywords`, { params: { type: 'FILTER' } })
    .then((res) => res.data);
// * type은 FILTER 고정, 이후 기획이 추가될 여지가 있으므로 개발단계에서 추가

export const getProducts = ({ keywordId, storeCode }) =>
  clientV3
    .get(`/www/stores/${storeCode}/products`, { params: { keywordId } })
    .then((res) => res.data);

export const getNotices = ({ storeCode, type }) =>
  clientV3
    .get(`/www/stores/${storeCode}/notices`, { params: { type } })
    .then((res) => res.data.notices[0]);

export const getProduct = (productId) =>
  clientV3.get(`/www/products/${productId}`).then((res) => res.data);

export const createPaymentPage = (data) =>
  client.post('/www/payment-page', data).then((res) => res.data);
