import Icon from 'components/Icons/v2/Icon';
import {
  Container,
  ConvenienceStoreMapModal,
  ProductBox,
  SectionInnerText,
  SectionInnerTitle,
  SectionTitle,
} from 'components/mypage/myOrder/orderHistoryDetail';
import styled from 'styled-components';

const OrderProducts = ({ comment, products }) => {
  return (
    <Container>
      <SectionTitle>주문메뉴</SectionTitle>
      <ContentsBox>
        {products.map((product, index) => (
          <ProductBox key={`order-product-${index}`} product={product} />
        ))}
      </ContentsBox>

      {comment && <CommentBox>{comment}</CommentBox>}
    </Container>
  );
};

const ContentsBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const CommentBox = styled.div`
  display: flex;
  padding: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  align-self: stretch;
  border-radius: 8px;
  background: var(--Gray-gray-1, #f7f8f9);
`;

export default OrderProducts;
