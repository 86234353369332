import {
  BUILD_TYPE_DEVELOPMENT,
  BUILD_TYPE_PRODUCTION,
  BUILD_TYPE_STAGING,
  getBuildType,
} from './build-configs';

export const GTM_ID = 'GTM-MRPVMZ4B';

export const GTM_STAGING_AUTH = '9cxcRO1Exk71VfzUD3DF5Q';
export const GTM_STAGING_PREVIEW = 'env-3';

export const GTM_DEV_AUTH = 'XeKX7cNghYPv5F9sB2fX8g';
export const GTM_DEV_PREVIEW = 'env-4';

export const getGtmTokens = () => {
  let gtmId;
  let gtmAuth;
  let gtmPreview;
  const buildType = getBuildType();
  if (buildType === BUILD_TYPE_PRODUCTION) {
    gtmId = GTM_ID;
  } else if (buildType === BUILD_TYPE_STAGING) {
    gtmId = GTM_ID;
    gtmAuth = GTM_STAGING_AUTH;
    gtmPreview = GTM_STAGING_PREVIEW;
  } else if (buildType === BUILD_TYPE_DEVELOPMENT) {
    gtmId = GTM_ID;
    gtmAuth = GTM_DEV_AUTH;
    gtmPreview = GTM_DEV_PREVIEW;
  }

  return gtmId
    ? {
        gtmAuth,
        gtmId,
        gtmPreview,
      }
    : null;
};
