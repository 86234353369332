import client from 'api/client';
import Icon from 'components/Icons/v2/Icon';
import {
  Container,
  InfoIcon,
  SectionInnerText,
  SectionInnerTitle,
  SectionTitle,
} from 'components/mypage/myOrder/orderHistoryDetail';
import { ToastContext } from 'context/toast';
import { useContext } from 'react';
import styled from 'styled-components';
import { getPayType, mobileIosCheck, moneyFormatter } from 'utils';

const PaymentInfo = ({ orderCode, payInfo }) => {
  const {
    deliveryFee,
    payType,
    totalDiscountAmount,
    totalPaymentAmount,
    totalProductAmount,
  } = payInfo;
  const { showToast } = useContext(ToastContext);
  const mobileIOS = mobileIosCheck();

  const handleReceiptClick = () => {
    if (mobileIOS && navigator.userAgent.includes('tpirates') === false) {
      showToast('앱에서 확인할 수 있습니다.');
    } else {
      client
        .get(`/www/quick/my/payment/receipt/${orderCode}`)
        .then(({ data }) => {
          window.open(data, '_blank');
        })
        .catch((error) => {
          if (error) {
            showToast('영수증을 불러오는데 실패했습니다.');
          }
        });
    }
  };

  return (
    <Container>
      <SectionTitleBox>
        <SectionTitle>결제정보</SectionTitle>
        <DetailBox onClick={() => handleReceiptClick()}>
          <DetailTypo>영수증 확인</DetailTypo>
          <Icon color="#2F80ED" name="arrow_right-16_1" size={16} />
        </DetailBox>
      </SectionTitleBox>

      <InfoBox>
        <Box>
          <SectionInnerTitle>결제수단</SectionInnerTitle>
          <SectionInnerText>{getPayType(payType)}</SectionInnerText>
        </Box>
        <Box>
          <SectionInnerTitle>상품금액</SectionInnerTitle>
          <SectionInnerText>
            {moneyFormatter(totalProductAmount)}원
          </SectionInnerText>
        </Box>

        {totalDiscountAmount ? (
          <Box>
            <SectionTitleWithIconTypo>할인금액</SectionTitleWithIconTypo>
            <SectionInnerText>
              {moneyFormatter(totalDiscountAmount)}원
            </SectionInnerText>
          </Box>
        ) : null}

        {deliveryFee ? (
          <Box>
            <SectionTitleWithIconBox>
              <SectionTitleWithIconTypo>배송비</SectionTitleWithIconTypo>
              <InfoIcon>고객님 댁과 픽업지 거리에 따라 발생합니다.</InfoIcon>
            </SectionTitleWithIconBox>
            <SectionInnerText>{moneyFormatter(deliveryFee)}원</SectionInnerText>
          </Box>
        ) : null}

        <Box>
          <TotalPriceTitleTypo>총 결제금액</TotalPriceTitleTypo>
          <TotalPriceTypo>
            {moneyFormatter(totalPaymentAmount)}원
          </TotalPriceTypo>
        </Box>
      </InfoBox>
    </Container>
  );
};

const TotalPriceTypo = styled.div`
  overflow: hidden;
  color: var(--Gray-gray-11, #26282b);
  text-align: right;
  text-overflow: ellipsis;
  font-family: 'Noto Sans KR';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.3px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  flex: 1 0 0;
`;

const TotalPriceTitleTypo = styled.div`
  overflow: hidden;
  color: var(--Gray-gray-11, #26282b);
  text-overflow: ellipsis;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: -0.3px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
`;

const DetailTypo = styled.div`
  color: var(--new-blue-blue-9, #2f80ed);
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.3px;
`;

const DetailBox = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
  cursor: pointer;
`;

const SectionTitleWithIconTypo = styled.div`
  overflow: hidden;
  color: var(--Gray-gray-6, #8a8d90);
  text-overflow: ellipsis;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.3px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
`;

const SectionTitleWithIconBox = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 2px;
`;

const SectionTitleBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Box = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export default PaymentInfo;
