import { Modal } from 'components/common';
import Icon from 'components/Icons/v2/Icon';
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { loadScript } from 'utils/loadScript';

const initMap = async ({
  containerId,
  currentMarkerRef,
  location,
  setIsActiveCurrentLocation,
}) => {
  await loadScript();
  const { naver } = window;
  if (!naver) return;

  const mapOptions = {
    center: new window.naver.maps.LatLng(location.latitude, location.longitude),
    disableDoubleClickZoom: true,
    disableDoubleTapZoom: true,
    disableTwoFingerTapZoom: true,
    keyboardShortcuts: false,
    mapDataControl: false,
    maxZoom: 22,
    minZoom: 12,
    pinchZoom: false,
    scaleControl: true,
    zoom: 20,
  };
  const map = new naver.maps.Map(containerId, mapOptions);

  // 편의점 위치 마커
  new naver.maps.Marker({
    map,
    position: new naver.maps.LatLng(location.latitude, location.longitude),
  });

  // 현재 위치 버튼
  const currentLocationButton = document.querySelector(
    '#current-location-button'
  );

  // 현재 위치 버튼 이벤트
  window.navigator.geolocation.getCurrentPosition((position) => {
    if (position) {
      naver.maps.Event.addDOMListener(currentLocationButton, 'click', () => {
        const point = {
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        };

        map.panTo(new naver.maps.LatLng(point.latitude, point.longitude), {
          duration: 400,
          easing: 'easeOutCubic',
        });

        // 현재 위치 마커
        const currentMarker = new naver.maps.Marker({
          animation: naver.maps.Animation.BOUNCE,
          map,
          position: new naver.maps.LatLng(point.latitude, point.longitude),
        });
        currentMarkerRef.current?.setMap(null);
        currentMarkerRef.current = currentMarker;

        setIsActiveCurrentLocation(true);
      });
    }
  });

  // 줌 컨트롤러
  const zoomUpControl = document.querySelector(
    '#convenience-store-map-zoom-plus'
  );
  const zoomDownControl = document.querySelector(
    '#convenience-store-map-zoom-minus'
  );
  naver.maps.Event.addDOMListener(zoomUpControl, 'click', () => {
    const currentZoomLevel = map.getZoom();
    map.setZoom(currentZoomLevel + 1, true);
  });
  naver.maps.Event.addDOMListener(zoomDownControl, 'click', () => {
    const currentZoomLevel = map.getZoom();
    map.setZoom(currentZoomLevel - 1, true);
  });

  return map;
};

const mapStyle = {
  height: '100%',
  maxWidth: '420px',
  position: 'relative',
  width: '100%',
};

const ConvenienceStoreMapModal = ({
  address,
  location,
  onClose,
  state,
  title,
}) => {
  const [isActiveCurrentLocation, setIsActiveCurrentLocation] = useState(false);
  const currentMarkerRef = useRef(null);

  useEffect(() => {
    initMap({
      containerId: 'convenience-store-map',
      currentMarkerRef,
      location,
      setIsActiveCurrentLocation,
    });
  }, [state]);

  return (
    <Modal
      state={state}
      title={title}
      onClose={() => {
        onClose();
        setIsActiveCurrentLocation(false);
      }}
    >
      <AddressTypo>{address}</AddressTypo>

      <MapContainer>
        <div id="convenience-store-map" style={mapStyle} />

        <MapControlBox>
          <CurrentLocationButton
            id="current-location-button"
            isActiveCurrentLocation={isActiveCurrentLocation}
          >
            <Icon
              color={isActiveCurrentLocation ? 'white' : '#464C53'}
              name="gps-24"
              size={24}
            />
          </CurrentLocationButton>

          <ZoomControlBox>
            <ZoomControlButton id="convenience-store-map-zoom-plus">
              <Icon color="#464C53" name="plus-24_1" size={24} />
            </ZoomControlButton>
            <ZoomControlButtonDivider />
            <ZoomControlButton id="convenience-store-map-zoom-minus">
              <Icon color="#464C53" name="minus-24_1" size={24} />
            </ZoomControlButton>
          </ZoomControlBox>
        </MapControlBox>
      </MapContainer>
    </Modal>
  );
};

const ZoomControlButtonDivider = styled.div`
  width: 100%;
  height: 1px;
  background: var(--Gray-gray-2, #f3f3f3);
`;

const ZoomControlButton = styled.div`
  max-height: 24px;
  padding: 4px;
  cursor: pointer;
`;

const ZoomControlBox = styled.div`
  background-color: white;
  border-radius: 4px;
  border: 1px solid var(--Gray-gray-2, #f3f3f3);
  height: 65px;
  box-shadow: 0px 1px 7px 0px rgba(0, 0, 0, 0.13);
`;

const CurrentLocationButton = styled.div`
  border-radius: 4px;
  border: 1px solid var(--Gray-gray-2, #f3f3f3);
  background: ${({ isActiveCurrentLocation }) =>
    isActiveCurrentLocation
      ? 'var(--Blue-blue-9, #2F80ED)'
      : 'var(--black-white-white, #fff)'};
  box-shadow: 0px 1px 7px 0px rgba(0, 0, 0, 0.13);
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const MapControlBox = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
`;

const MapContainer = styled.div`
  overflow: hidden;
  border-radius: 0 0 16px 16px;
  position: relative;
  height: 70vh;
`;

const AddressTypo = styled.div`
  padding: 0 16px 24px 16px;
  color: var(--gray-gray400, #72787f);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.3px;
`;

export default ConvenienceStoreMapModal;
