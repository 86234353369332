import './style.css';

import { Box, Fish, Person, Ship, Store } from '@thepirates/icons';
import { conf } from 'conf/settings';
import { useWholeSaleSortState } from 'hooks/custom/useWholeSaleSortState';
import useScrollDetect from 'hooks/useScrollDetect';
import fishImg from 'img/ftp_fa_fish.svg';
import fishFillImg from 'img/ftp_fa_fish_fill.svg';
import graphImg from 'img/graph.svg';
import graphFillImg from 'img/graph_fill.svg';
import homeImg from 'img/home.svg';
import homeFillImg from 'img/home_fill.svg';
import manImg from 'img/man.svg';
import manFillImg from 'img/man_fill.svg';
import shoppingImg from 'img/shopping.svg';
import shoppingFillImg from 'img/shopping_fill.svg';
import { memo, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { useSetRecoilState } from 'recoil';
import { globalListOrder } from 'recoil/filter';
import { globalPriceFilter, globalStoresFilter } from 'recoil/price';
import { globalSearchKeyword } from 'recoil/search';
import styled from 'styled-components';
import { mobileIosCheck } from 'utils/mobileCheck';

import AdditionalFunction from './additionalFunc';

const initStoresFilterState = {
  around: [],
  location: [],
  orderType: [],
  product: [],
};

const initPriceFilterState = {
  around: [],
  location: [],
  origin: [],
  product: [],
  raised: [],
};

const footerMenus = () => {
  return [
    {
      fill: homeFillImg,
      icon: <Fish />,
      img: homeImg,
      name: '수산시장',
      path: '/',
    },
    {
      fill: shoppingFillImg,
      icon: <Ship />,
      img: shoppingImg,
      name: '산지마켓',
      path: conf.OPEN_MARKET_URL,
    },
    {
      fill: graphFillImg,
      icon: <Store />,
      img: graphImg,
      name: '쇼핑몰',
      path: conf.SHOPPING_MALL_URL,
    },
    {
      fill: fishFillImg,
      icon: <Box />,
      img: fishImg,
      name: '도매',
      path: '/도매',
    },
    {
      fill: manFillImg,
      icon: <Person />,
      img: manImg,
      name: 'MY',
      path: conf.MY_URL,
      // isLogin && isLogin !== 'null' && isLogin !== 'notLogin'
      //   ? conf.MY_URL
      //   : getAccountUrl({ redirectUrl: conf.MY_URL, type: 'FISH' }),
    },
  ];
};
const SideFooterPage = ['/도매'];

const FooterItem = ({ item }) => {
  const { name, path } = item;
  const { pathname } = useLocation();
  const history = useHistory();
  const setGlobalSearchKeyword = useSetRecoilState(globalSearchKeyword);
  const setStoresFilter = useSetRecoilState(globalStoresFilter);
  const setPriceFilter = useSetRecoilState(globalPriceFilter);

  const setListOrder = useSetRecoilState(globalListOrder);
  const { initWholeSaleSortState } = useWholeSaleSortState();

  const click = useCallback(() => {
    try {
      const url = new URL(path);
      window.location.href = url.href;
    } catch {
      history.push(path);
    }
  }, [history, path]);

  const isActive =
    (name === '수산시장' &&
      !pathname.startsWith('/도매') &&
      !pathname.startsWith('/마이페이지')) ||
    (name === '도매' && pathname.startsWith('/도매')) ||
    (name === 'MY' && pathname.startsWith('/마이페이지'));

  return (
    <figure className={isActive ? 'ON' : ''}>
      <StyledLinkButton
        onClick={(e) => {
          e.preventDefault();
          click(e);
          setGlobalSearchKeyword('');
          setStoresFilter(initStoresFilterState);
          setPriceFilter(initPriceFilterState);
          initWholeSaleSortState();
          setListOrder('default');
          sessionStorage.removeItem('mainPath');
        }}
      >
        <IconWrapper active={isActive}>{item.icon}</IconWrapper>
        <span>{name}</span>
      </StyledLinkButton>
    </figure>
  );
};
const IconWrapper = styled.div`
  & > svg {
    fill: ${({ active }) => (active ? '#0e4e97' : '#9fa4aa')};
  }
`;

const StyledLinkButton = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: transparent;
  cursor: pointer;
`;

const FooterFixed = ({ smallHeader }) => {
  const isOpen = useSelector((state) => state.nav.isOpen);
  const location = useLocation();

  const sideFooter = useMemo(
    () => !!SideFooterPage.includes(location.pathname),
    [location]
  );

  const include = useMemo(() => {
    const colorExclude = ['/스토어', '/도매'];
    return {
      color: !colorExclude.includes(location.pathname),
    };
  }, [location]);

  const mobileIOS = mobileIosCheck();

  const { isDown } = useScrollDetect();

  const ColorFilter = useMemo(() => {
    return (
      <p
        id="footer-color-filter"
        style={{
          background: 'rgba(0,0,0,0.3)',
          display: 'none',
          height: '100%',
          left: '0px',
          position: 'absolute',
          top: '0px',
          width: '100%',
        }}
      />
    );
  }, []);
  return (
    isOpen && (
      <FooterFixedStyle
        $downFlag={isDown}
        className={`footer-fixed ${smallHeader ? 'with-small-header' : ''}${
          sideFooter ? 'side-footer' : ''
        } ${include.color ? 'market' : ''}`}
        mobileIOS={mobileIOS}
      >
        {!location.search.includes('detail') &&
          !location.pathname.includes('search') &&
          !location.pathname.includes('마이페이지') && (
            /** 맨위로 버튼 등 */
            <AdditionalFunction />
          )}
        <div className="footer-change-wrap">
          {footerMenus().map((v, i) => {
            return <FooterItem key={i} item={v} />;
          })}
          {ColorFilter}
        </div>
      </FooterFixedStyle>
    )
  );
};

const FooterFixedStyle = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  min-width: 360px;
  max-width: 420px;
  width: 100%;

  box-sizing: border-box;
  z-index: 25;

  @media (max-width: 576px) {
    transform: ${({ $downFlag }) => `translateY(${$downFlag ? '100%' : '0%'})`};
    transition: transform 0.3s ease-in-out;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    min-width: 360px;
    max-width: 420px;
    width: 100%;

    box-sizing: border-box;
    z-index: 25;
  }

  &.market > .footer-change-wrap {
    @media only screen and (max-width: 576px) {
      padding: 0px 10px constant(safe-area-inset-bottom);
      padding: 0px 10px env(safe-area-inset-bottom);
      height: ${({ mobileIOS }) => (mobileIOS ? 'auto' : '65px')};
      padding-top: ${({ mobileIOS }) => (mobileIOS ? '10px' : '0px')};
    }

    & > figure {
      & > span {
        margin: 0;
      }
      &.ON {
        & > span {
          color: #0e4e97;
        }
      }
    }
  }
  & > .footer-change-wrap {
    @media only screen and (max-width: 576px) {
      padding: 0px 10px constant(safe-area-inset-bottom);
      padding: 0px 10px env(safe-area-inset-bottom);
      height: ${({ mobileIOS }) => (mobileIOS ? 'auto' : '65px')};
      padding-top: ${({ mobileIOS }) => (mobileIOS ? '10px' : '0px')};
    }
    display: flex;
    box-sizing: border-box;
    width: 100%;
    background: #fff;
    border-top: 1px solid ${({ theme }) => theme.gray100};
    /* box-shadow: 0 -0.125rem 0.25rem rgb(0 0 0 / 8%) !important; */
    &::after {
      display: block;
      content: '';
      clear: both;
      height: 0;
    }
    & > figure {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-flow: column;

      height: 100%;
      margin: 0;
      padding: 0;

      cursor: pointer;
      /* & > img {
        width: 20px;
        height: 20px;
        border: none;
        filter: invert(66%) sepia(22%) saturate(0%) hue-rotate(213deg)
          brightness(91%) contrast(77%);
      } */
      & span {
        font-size: 12px;
        line-height: 12px;
        color: #999999;
        margin-top: 5px;
        font-weight: 400;
      }

      & span {
        margin: 0;
        font-size: 12px;
        line-height: 21px;
        letter-spacing: -1px;
      }
      &.ON {
        & span {
          color: #0e4e97;
        }
      }
    }
  }

  @media (min-width: 576px) {
    & > .footer-change-wrap {
      left: none;
      right: none;
      display: flex;

      position: fixed;
      top: 100px;
      /* right: -86.5px; */
      margin-left: -88px;
      padding: 16px 0;
      padding-left: 0;
      padding-right: 0;

      flex-flow: column;
      width: 88px;
      height: auto;
      /* box-shadow: 2px 0 8px inset lightgrey !important; */
      border-radius: 5px 0 0 5px;
      /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
      background: #fff;
      border: 1px solid ${({ theme }) => theme.gray100};
      transition: top 0.3s;

      & > figure {
        width: 100%;
        flex: 0 0 71px;
        & > img {
        }
        & > span {
        }
      }
    }
    &.with-small-header {
      & > .footer-change-wrap {
      }
    }
  }

  &.side-footer {
    display: none;
  }
`;

export default memo(FooterFixed);
