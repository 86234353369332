import './style.css';

import CardView from './_card-view';
import GalleryView from './_gallery-view';
import TicketView from './_ticket-view';

const CardList = ({ children, items, type }) => {
  let card_type = 'section ';
  let renderer = (list) => <></>;

  switch (type) {
    case 'ticket':
      // card_type += 'card-list1 flicking';
      renderer = (list) => <TicketView items={list} />;
      break;
    case 'card':
      card_type += 'card-list2';
      renderer = (list) => <CardView items={list} />;
      break;
    case 'gallery':
      card_type += 'card-list3';
      renderer = (list) => <GalleryView items={list} />;
      break;
  }

  return <div className={card_type}>{items ? renderer(items) : children}</div>;
};

export default CardList;
