import Icon from 'components/Icons/v2/Icon';
import useGetStorePhone from 'hooks/Query/Cart/useGetStorePhone';
import { useState } from 'react';
import styled from 'styled-components';
import { phoneParser } from 'utils';

const InquirySlideContents = ({ storeCode }) => {
  const [isStoreConnectionType, setIsStoreConnectionType] = useState(false);
  const [titleText, setTitleText] = useState('');

  const { data } = useGetStorePhone(storeCode);

  return (
    <Wrap>
      {titleText === '' && (
        <div>
          <ButtonWrap>
            <StyledButton
              onClick={() => {
                setIsStoreConnectionType(true);
                setTitleText('퀵 시간을 변경하고 싶어요');
              }}
            >
              퀵 시간을 변경하고 싶어요
            </StyledButton>
            <StyledButton
              onClick={() => {
                setTitleText('포장 픽업시간을 변경하고 싶어요');
              }}
            >
              포장 픽업시간을 변경하고 싶어요
            </StyledButton>
            <StyledButton
              onClick={() => {
                setIsStoreConnectionType(true);
                setTitleText('주문이 자동으로 취소되었어요');
              }}
            >
              주문이 자동으로 취소되었습니다
            </StyledButton>
            <StyledButton
              onClick={() => {
                setIsStoreConnectionType(true);
                setTitleText('주문을 취소하고 싶어요');
              }}
            >
              주문을 취소하고 싶어요
            </StyledButton>
            <StyledButton
              onClick={() => {
                setTitleText('요청사항을 추가하고 싶어요');
              }}
            >
              요청사항을 추가하고 싶어요
            </StyledButton>
          </ButtonWrap>
        </div>
      )}

      {titleText !== '' && !isStoreConnectionType && (
        <Container>
          <Box>
            <TitleTypo>{titleText}</TitleTypo>
            <Typo>가게로 바로 연결됩니다.</Typo>
          </Box>
          <StyledButton
            onClick={() => (window.location.href = `tel:${data.phone}`)}
          >
            <Icon color="#464C53" name="call_filled-24" />
            {phoneParser(data.phone)}(주간)
          </StyledButton>
        </Container>
      )}

      {titleText !== '' && isStoreConnectionType && (
        <Container>
          <TitleTypo>{titleText}</TitleTypo>
          <Box>
            <StyledButton
              onClick={() =>
                (window.location.href = 'https://pf.kakao.com/_lVxhbl/chat')
              }
            >
              <Icon color="#464C53" name="pencil_filled-24_1" />
              1:1문의
            </StyledButton>
            <StyledButton
              onClick={() => (window.location.href = `tel:02-2068-3241`)}
            >
              <Icon color="#464C53" name="customer-24_1" />
              고객센터
            </StyledButton>
          </Box>
          <Box>
            <SectionTitle>상담 가능시간</SectionTitle>
            <ContentsTextBox>
              <DateBox>
                <Dot />
                <div>월 ~ 금</div>
              </DateBox>
              <ColBox>
                <Typo color="var(--Blue-blue-9, #2F80ED)">09:30 ~ 18:00</Typo>
                <Typo color="var(--Gray-gray-6, #8A8D90)">
                  점심시간 13:00 ~ 14:00
                </Typo>
              </ColBox>
            </ContentsTextBox>

            <ContentsTextBox>
              <DateBox>
                <Dot />
                <div>주말/공휴일</div>
              </DateBox>
              <ColBox>
                <Typo color="var(--Blue-blue-9, #2F80ED)">09:30 ~ 18:00</Typo>
                <Typo color="var(--Gray-gray-6, #8A8D90)">
                  점심시간 13:00 ~ 14:00
                </Typo>
              </ColBox>
            </ContentsTextBox>

            <ContentsTextBox>
              <DateBox>
                <Dot />
                <div>명절 당일</div>
              </DateBox>
              <ColBox>
                <Typo color="var(--Blue-blue-9, #2F80ED)">휴무</Typo>
              </ColBox>
            </ContentsTextBox>
          </Box>
        </Container>
      )}
    </Wrap>
  );
};

const ColBox = styled.div`
  display: flex;
  flex-direction: column;
`;

const Typo = styled.span`
  color: ${({ color }) => color ?? `var(--Gray-gray-11, #26282b)`};
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.3px;
`;

const Dot = styled.span`
  width: 2px;
  height: 2px;
  border-radius: 50%;
  background-color: black;
`;

const DateBox = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  width: 100px;
`;

const ContentsTextBox = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 4px;
`;

const SectionTitle = styled.div`
  overflow: hidden;
  color: var(--new-gray-gray-11, #26282b);
  text-overflow: ellipsis;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.3px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const TitleTypo = styled.div`
  overflow: hidden;
  color: var(--Blue-blue-9, #2f80ed);
  text-overflow: ellipsis;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: -0.3px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  align-self: stretch;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const Wrap = styled.div`
  padding: 16px;
  height: 526px;
`;

const ButtonWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
const StyledButton = styled.button`
  border-radius: 4px;
  border: 1px solid var(--new-gray-gray-2, #e8ebed);
  background: var(--new-black-white-white, #fff);
  padding: 12px 16px;
  color: var(--new-gray-gray-10, #383b3e);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.3px;
  cursor: pointer;
  transition: ease-in-out 200ms;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2px;

  &:hover {
    background: var(--new-gray-gray-1, #f7f8f9);
  }
`;
const StyledButtonContents = styled.button`
  width: 100%;
  border-radius: 5px;
  background: ${({ kakao }) => (kakao ? '#FEF6E7' : '#F7F8F9')};
  border: 1px solid ${({ kakao }) => (kakao ? '#F2994A' : '#E8EBED')};
  cursor: pointer;
  padding: 15px 0;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.3px;
  margin-bottom: 16px;
`;
const ArrowLeft = styled(Box)`
  margin-top: 18px;
  cursor: pointer;
`;

const TextBox = styled(Box)`
  justify-content: start;
  width: 100%;
  flex-direction: column;
  margin-bottom: 16px;

  & > p {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.3px;
  }
`;

const ConsultationTime = styled.p`
  color: #2f80ed;
`;
export default InquirySlideContents;
