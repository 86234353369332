import { useQuery } from '@tanstack/react-query';
import { getOrderHistoryDetail } from 'api/mypage';

import myPageKeys from './keys';

export default function useOrderHistoryDetail(orderCode) {
  return useQuery(
    myPageKeys.getOrderHistoryDetail(),
    () => getOrderHistoryDetail(orderCode),
    {
      enabled: !!orderCode,
    }
  );
}
