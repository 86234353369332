import ChevronLeft from 'components/Icons/ChevronLeft';
import SingleLens from 'components/Icons/SingleLens';
import { removeSpecialChaRegex } from 'constants/marketFilter';
import { useToast } from 'hooks';
import { useWholeSaleSortState } from 'hooks/custom/useWholeSaleSortState';
import { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { globalListOrder, globalSearchStoreScrollPage } from 'recoil/filter';
import { globalPriceFilter, globalStoresFilter } from 'recoil/price';
import { eventSearchKeyword, globalSearchKeyword } from 'recoil/search';
import styled from 'styled-components';
import genPrevPath from 'utils/genPrevPath';

const COOKIE_KEY_RECENTLY = 'pirates-component/RECENTLY.KEYWORDS';

const SearchInput = ({ handleClickBack, recently, setCookie }) => {
  const [globalKeyword, setGlobalKeyword] = useRecoilState(globalSearchKeyword);
  const [storesFilter, setStoresFilter] = useRecoilState(globalStoresFilter);
  const [priceFilter, setPriceFilter] = useRecoilState(globalPriceFilter);
  const setEventKeyword = useSetRecoilState(eventSearchKeyword);
  const setListOrder = useSetRecoilState(globalListOrder);
  const [keyword, setKeyword] = useState(globalKeyword);
  const prevPath = sessionStorage.getItem('prevPath');
  const location = useLocation();
  const isWholeSale = location.pathname.includes('도매');
  const { initWholeSaleSortState } = useWholeSaleSortState();
  const setStorePage = useSetRecoilState(globalSearchStoreScrollPage);
  const { push } = useHistory();
  const showToast = useToast();

  const handleChange = (e) => {
    if (e.target.value.length > 16) return;
    setKeyword(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const cleanKeyword = keyword.replace(removeSpecialChaRegex, '');
    if (!cleanKeyword.length) {
      showToast('검색어를 다시 입력해주세요');
      return;
    }
    const keywords = [...recently, cleanKeyword].reduce(
      (acc, item) => (acc.includes(item) ? acc : [...acc, item]),
      []
    );
    setCookie(COOKIE_KEY_RECENTLY, keywords.slice(-10).join(','), {
      path: '/',
    });
    if (!globalKeyword.length) {
      const newObj = Object.keys(storesFilter).reduce((acc, key) => {
        acc[key] = [];
        return acc;
      }, {});
      setStoresFilter(newObj);

      const newPriceObj = Object.keys(priceFilter).reduce((acc, key) => {
        acc[key] = [];
        return acc;
      }, {});
      setPriceFilter(newPriceObj);
    }
    initWholeSaleSortState();
    setGlobalKeyword(cleanKeyword);
    setEventKeyword('');
    setListOrder('default');
    setStorePage(1);
    if (isWholeSale) {
      push('/도매');
      return;
    }
    push(genPrevPath(prevPath));
  };

  const clickBack = () => {
    handleClickBack();
  };

  return (
    <SearchContainer>
      <SearchWrap>
        <ChevronLeft fill="black" size={18} onClick={clickBack} />
        <InputWrapper>
          <form onSubmit={handleSubmit}>
            <Input
              placeholder="검색어를 입력하세요"
              type="text"
              value={keyword}
              onChange={handleChange}
            />
          </form>
          <SingleLens
            className="single-lens"
            fill="#464C53"
            size={18}
            onClick={handleSubmit}
          />
        </InputWrapper>
      </SearchWrap>
    </SearchContainer>
  );
};

const SearchContainer = styled.div`
  position: fixed;
  width: 100%;
  max-width: 420px;
  min-width: 360px;
  top: 0px;
  background: white;
`;
const SearchWrap = styled.div`
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  display: flex;
`;

const InputWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-start;
  width: 90%;
  .single-lens {
    position: absolute;
    top: 12px;
    right: 14px;
  }
  .close {
    position: absolute;
    width: 15px;
    top: 6px;
    right: 16px;
  }
  form {
    width: 100%;
  }
`;

const Input = styled.input`
  box-sizing: border-box;
  height: 40px;
  font-size: 16px;
  width: 100%;
  background-color: ${({ theme }) => theme.white200};
  border: 1px solid ${({ theme }) => theme.white200};
  border-radius: 100px !important;
  padding-left: ${({ inputFocus }) => (inputFocus ? '40px' : '16px')};
  &::placeholder {
    color: ${({ theme }) => theme.newGray5};
  }
  :focus {
    background-color: ${({ theme }) => theme.white100};
    border: 1px solid ${({ theme }) => theme.blue300};
    border-radius: 5px;
  }
`;

export default SearchInput;
