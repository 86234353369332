import { Box } from 'components/atoms';
import { ArrowRight } from 'components/Icons';
import DiscountCoupon from 'components/modules/DiscountCoupon';
import dayjs from 'dayjs';
import { useSaveCoupon } from 'hooks/Mutate/StoreDetail';
import { useCallback, useContext, useMemo } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { moneyFormatter } from 'utils/Formatter';

import * as coupon_api from '../../api/coupon';
import { ToastContext } from '../../context/toast';
import LoginContext, { View } from '../common/login/_context';
import Icon from '../Icons/v2/Icon';
import { Image } from '../views';
import downloadImg from './img/icon_download.svg';
import shape from './img/shape.png';

// 시장 홈에서 나오는 쿠폰
const SmallTicket = ({ item, onClick }) => {
  const removeSquareBrackets = (str) => {
    return str?.replace(/\[.*?\]/g, '');
  };

  const isPercentageType = item.discountType === 'PERCENTAGE';
  const isFixedAmountType = item.discountType === 'FIXED_AMOUNT';

  return item.itemName === '내 쿠폰 보기' ? (
    <ViewMyCouponContainer onClick={onClick}>
      <div>
        <ViewMyCouponTypo>내 쿠폰</ViewMyCouponTypo>
        <ViewMyCouponTypoBox>
          <ViewMyCouponTypo>보기</ViewMyCouponTypo>
          <Icon name="arrow_right-16_1" size={16} />
        </ViewMyCouponTypoBox>
      </div>
    </ViewMyCouponContainer>
  ) : (
    <SmallCouponContainer onClick={onClick}>
      <SmallCouponProductName isDownload={item.isDownload}>
        {item.itemName
          ? removeSquareBrackets(item.itemName)
          : removeSquareBrackets(item.productName)}
      </SmallCouponProductName>
      <SmallCouponProductDiscount isDownload={item.isDownload}>
        {isPercentageType && `${item.discountRate}% 할인`}
        {isFixedAmountType && `${moneyFormatter(item.discountPrice)}원 할인`}
      </SmallCouponProductDiscount>
      <img alt="shape" src={shape} style={{ width: '100%' }} />
      <SmallCouponStore>{item.storeName}</SmallCouponStore>
    </SmallCouponContainer>
  );
};

// 검색쿠폰
const LargeTicket = ({ item, onClick }) => {
  return (
    <div
      className="card-template coupon"
      style={{ cursor: 'pointer', padding: '16px 24px' }}
      onClick={onClick}
    >
      <Box
        color="#EB5757"
        // fontFamily="Open Sans"
        fontSize="16px"
        fontWeight={600}
        letterSpacing="-0.3px"
        lineHeight="20px"
      >
        {item.discountRate
          ? `${item.discountRate}%`
          : `${moneyFormatter(item.discountPrice)}원`}{' '}
        할인
      </Box>
      <Box alignItems="center">
        <Box
          color="#26282B"
          display="block"
          fontSize="18px"
          fontWeight={500}
          letterSpacing="-0.3px"
          lineHeight="26px"
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
        >
          {item.menuName}
        </Box>
        <Box
          color="#72787F"
          flexShrink={0}
          fontSize="14px"
          fontWeight={400}
          letterSpacing="-0.3px"
          lineHeight="20px"
          marginLeft="8px"
        >
          {item.store}
        </Box>
      </Box>
      <Box
        color="#72787F"
        fontSize="14px"
        fontWeight={400}
        letterSpacing="-0.3px"
        lineHeight="20px"
      >
        발급기간 : {dayjs(item.begin).format('YY.MM.DD(ddd)')} ~{' '}
        {dayjs(item.end).format('YY.MM.DD(ddd)')} 까지
      </Box>
    </div>
  );
};

const LargeDetailTicket = ({ item }) => {
  const { action } = useContext(LoginContext);
  const { showToast } = useContext(ToastContext);

  const saveCoupon = () => {
    coupon_api
      .save_coupon(item.id)
      .then((res) => {
        if (res.data.message === 'data inserted') {
          showToast('쿠폰 발급이 완료되었습니다.');
        } else {
          showToast('이미 보유 중인 쿠폰입니다.');
        }
      })
      .catch(() => {
        action.open(View.CHANNEL);
      });
  };
  return (
    <div className="coupon-image-detail-wrap">
      <div className="card-template coupon-image-detail">
        <div className="main">
          <div className="permalink">{`${item.marketLabel} ${item.store}`}</div>
          <div className="label">
            {item.menuName?.replace('[★]', '')}{' '}
            {item.promotion
              ? `${item.promotion}% 할인`
              : `${moneyFormatter(item.originDiscount)}원 할인`}
          </div>
          <Image scale="16x9" src={item.thumbnail} width="100%" />
          {item.items &&
            item.items.map((data, i) => (
              <div key={`coupon_info${i}`} className="description">
                <span className="option">{data.option}</span>
                {item.couponType === 'menu' && (
                  <>
                    <span className="discounted">
                      {moneyFormatter(data.discountPrice)}원
                    </span>
                    <span className="price">
                      {moneyFormatter(data.price)}원
                    </span>
                  </>
                )}
                {item.couponType !== 'menu' && (
                  <span className="discounted">
                    {item.promotion
                      ? `${item.promotion}% 할인`
                      : `${moneyFormatter(item.originDiscount)}원 할인`}
                  </span>
                )}
              </div>
            ))}
        </div>
        <div className="foot">
          <div className="info">
            {item.period ? item.period : ''} 사용 가능합니다. <span />
            <br />
            쿠폰을 받은 후 가게 방문 시 보여주세요.
          </div>
          <div className="btn-area">
            <Link
              className="move-menu"
              to={
                item.permalink
                  ? `/${item.permalink}?${item.couponType || 'menu'}=${
                      item.menuGroupCode
                    }`
                  : '#'
              }
            >
              <button className="move-menu">메뉴 상세 보기</button>
            </Link>
            <a href="#" onClick={(e) => e.preventDefault()}>
              <button className="get-coupon" onClick={saveCoupon}>
                할인쿠폰 받기
                <img alt="" src={downloadImg} />
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

const LargeSimplifyTicket = ({ item, onClick, session, storeCode }) => {
  const { action } = useContext(LoginContext);
  const { showToast } = useContext(ToastContext);

  const { mutateAsync } = useSaveCoupon(storeCode, item.id);

  const onClickCoupon = useCallback(
    (e) => {
      e.stopPropagation();
      if (!session.identity) {
        sessionStorage.setItem(
          'preLoginState',
          JSON.stringify({ isStoreDetailCoupon: item.id })
        );

        action.open(View.CHANNEL);
      } else {
        mutateAsync(item.id)
          .then((res) => {
            if (res.message === 'data inserted') {
              showToast('쿠폰 발급이 완료되었습니다.');
            } else {
              showToast('이미 보유 중인 쿠폰입니다.');
            }
          })
          .catch((err) => {
            showToast('쿠폰을 불러오는데 실패했습니다.');
          });
      }
    },
    [action, item.id, storeCode]
  );

  return (
    <DiscountCoupon
      {...item}
      mutateAsync={mutateAsync}
      session={session}
      onClick={onClick}
      onDownload={onClickCoupon}
    />
  );
};

const CustomLineText = ({ children, height = 20, max = 1, ...props }) => {
  return (
    <CustomLineTextStyle
      ref={(ref) => {
        if (ref) {
          while (ref.getBoundingClientRect().height > max * height) {
            ref.innerText = `${ref.innerText.substr(
              0,
              ref.innerText.length - 4
            )}...`;
          }
        }
      }}
      style={{
        lineHeight: `${height}px`,
        ...props.style,
      }}
      {...props}
    >
      {children}
    </CustomLineTextStyle>
  );
};

const CustomLineTextStyle = styled.div`
  display: block;

  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.3px;
  color: ${({ theme }) => theme.gray500};

  white-space: pre-wrap !important;
  overflow: visible !important;
  text-overflow: clip !important;
  word-break: break-all;
`;

const ImageTicket = ({ item, onClick }) => {
  const isPercentageType =
    item.discountRate && item.discountType === 'PERCENTAGE';
  const isFixedAmountType = item.discountType === 'FIXED_AMOUNT';

  const discountPrice = useMemo(() => {
    if (isPercentageType) return item.discountRate;
    if (isFixedAmountType) return `${moneyFormatter(item.discountPrice)}`;
    return '';
  }, [item]);

  const discountUnit = useMemo(() => {
    if (isPercentageType) return '% 할인';
    if (isFixedAmountType) return '원 할인';
    return '';
  }, [item]);

  return (
    <ImageTicketStyle style={{ cursor: 'pointer' }} onClick={onClick}>
      <ImageBox
        style={{
          background: `url(${
            item.imageUrl || ''
          }) no-repeat center center /cover #dddddd`,
        }}
      />
      <CouponDetail>
        <DiscountPriceWrap>
          <DiscountPrice>{discountPrice}</DiscountPrice>
          <DiscountText>{discountUnit}</DiscountText>
        </DiscountPriceWrap>

        <MenuNameBox>
          <CustomLineText max={2}>
            {item.itemName || item.productName}
          </CustomLineText>
        </MenuNameBox>
        <DiscountDate>
          {dayjs(item.useEndDate).format(`YY.MM.DD 까지`)}
          {item.useRemains > 0 ? `(D-${item.useRemains})` : ''}
        </DiscountDate>
      </CouponDetail>
      <StoreMoveButton
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <Link
          to={{
            pathname: `/${item.permalink}`,
            state: '쿠폰',
          }}
        >
          <MarketLabelWrap>
            <MarketLabel>
              <p>{item.marketName}</p>
              <p>{item.storeName}</p>
            </MarketLabel>
            <ArrowRight fill="#9FA4AA" size="13" />
          </MarketLabelWrap>
        </Link>
      </StoreMoveButton>
    </ImageTicketStyle>
  );
};

const ImageTicketStyle = styled.div`
  box-sizing: border-box;
  border: ${({ theme }) => `1px solid ${theme.gray200}`};
  background-color: #fff;
  position: relative;
  display: block;
  height: auto;
  overflow: hidden;
  border-radius: 5px;
  cursor: pointer;
`;

const ImageBox = styled.div`
  width: 100%;
  height: 112px;
`;

const CouponDetail = styled.div`
  padding: 12px 12px 0px 12px;
`;

const DiscountPriceWrap = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 4px;
`;

const DiscountPrice = styled.div`
  font-size: 16px;
  font-weight: 600;
  /* font-family: 'Open Sans'; */
  line-height: 20px;
  letter-spacing: -0.3px;
  color: ${({ theme }) => theme.red2};

  margin-right: 1px;
`;

const DiscountText = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.3px;
  color: ${({ theme }) => theme.red2};
`;

const MenuNameBox = styled.div`
  display: block;
  position: relative;
  height: 37px;
  width: 100%;

  margin-bottom: 4px;
`;

const DiscountDate = styled.div`
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: -0.3px;
  color: ${({ theme }) => theme.yellow300};
  padding-bottom: 8px;
  border-bottom: ${({ theme }) => `1px solid ${theme.gray100}`};
`;

const StoreMoveButton = styled.div`
  padding: 0px 16px;
`;

const MarketLabelWrap = styled.div`
  padding: 8px 0 12px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const MarketLabel = styled.div`
  & > p {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: -0.3px;
    color: ${({ theme }) => theme.gray400};
  }
`;

const SmallCouponContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 104px;
  width: 134px;
  border-radius: 12px;
  background: #fff;
  box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.13);
`;

const SmallCouponProductName = styled.div`
  overflow: hidden;
  color: ${({ isDownload }) => (isDownload ? '#9FA4AA' : '#EB5757')};
  text-align: center;
  text-overflow: ellipsis;
  font-size: 12px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: -0.3px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  padding: 12px 12px 2px 12px;
`;

const SmallCouponProductDiscount = styled.div`
  overflow: hidden;
  color: ${({ isDownload }) => (isDownload ? '#9FA4AA' : '#EB5757')};
  text-align: center;
  text-overflow: ellipsis;
  font-size: 18px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: -0.3px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  padding: 0 12px 12px 12px;
`;

const SmallCouponStore = styled.div`
  overflow: hidden;
  color: var(--Gray-gray-5, #9fa4aa);
  text-align: center;
  text-overflow: ellipsis;
  font-size: 10px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -0.3px;
  display: -webkit-box;
  width: 110px;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  padding: 4.5px 12px 8px 12px;
`;

const ViewMyCouponContainer = styled.div`
  border-radius: 12px;
  background: #fff;
  box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.13);
  width: 70px;
  height: 104px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ViewMyCouponTypo = styled.div`
  overflow: hidden;
  color: var(--Gray-gray-11, #26282b);
  text-overflow: ellipsis;
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: -0.3px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
`;

const ViewMyCouponTypoBox = styled.div`
  display: flex;
  align-items: center;
`;

const Ticket = ({ item, onClick, session, size, storeCode }) => {
  switch (size) {
    case 'image':
      return <ImageTicket item={item} onClick={onClick} />;

    case 'large':
      return <LargeTicket item={item} onClick={onClick} />;

    case 'large-detail':
      return <LargeDetailTicket item={item} onClick={onClick} />;

    case 'large-simplify':
      return (
        <LargeSimplifyTicket
          item={item}
          session={session}
          storeCode={storeCode}
          onClick={onClick}
        />
      );
    case 'small':
      return <SmallTicket item={item} onClick={onClick} />;
  }
};

export default Ticket;
