import { retryLazy } from 'utils/retryLazy';

export const Home = retryLazy(() => import('./_home/_home'));
export const Stores = retryLazy(() => import('./_store/_list/Stores'));
export const Store = retryLazy(() => import('./_store/_view'));
export const ReviewEvent = retryLazy(() => import('./_store/_review_event'));
export const Contents = retryLazy(() => import('./_content/_list'));
export const Content = retryLazy(() => import('./_content/_view'));
export const Price = retryLazy(() => import('./_price'));
export const PriceDetail = retryLazy(() => import('./Price/PriceDetail'));
export const Wholesale = retryLazy(() => import('./_biz/_list'));
export const RecommendPrice = retryLazy(() => import('./RecommendPrice'));
export { default as WholesaleItem } from './_biz/_item';
export { default as WholesaleOrder } from './_biz/_orderlist';
export const Reviews = retryLazy(() => import('./_reviews'));
export { default as Coupons } from './_coupons/_coupons_new';
export { default as MyCoupon } from './_mypage/_my_coupon_new';
export { default as MyLike } from './_mypage/_my_like_new';
export { default as MyMessage } from './_mypage/_my_message_new';
export { default as MyOrder } from './_mypage/_my_order_new';
export { default as MyReview } from './_mypage/_my_review_new';
export { default as MyPage } from './_mypage/_mypage_new';
export { default as OrderHistoryDetail } from './_mypage/OrderHistoryDetail';
export const Callback = retryLazy(() => import('./Callback'));
// export { default as HowToOrder } from './HowToOrder/index';
export const ProductDetail = retryLazy(() =>
  import('./_store/_product_detail')
);
export const UpdateProductDetail = retryLazy(() =>
  import('./_store/UpdateProductDetail')
);
export { default as OrderConfirm } from './Order/Confirm';
export { default as PortalSearch } from './PortalSearch';
