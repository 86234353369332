import shape from 'components/coupons/img/shape.png';
import { ToastContext } from 'context/toast';
import { useFindOutDragging } from 'hooks/custom/useFindOutDragging';
import { useContext, useEffect } from 'react';
import styled from 'styled-components';

import Icon from '../../Icons/v2/Icon';

const DiscountCoupon = ({
  discountPrice,
  discountRate,
  id,
  isDownload,
  itemName,
  mutateAsync,
  onClick,
  onDownload,
  productName,
  session,
}) => {
  const isLogin = session && session.identity !== null;
  const { handleMouseDown, handleMouseUp, isDragging } = useFindOutDragging();

  const { showToast } = useContext(ToastContext);

  // 비로그인상태에서 쿠폰받기 후 돌아왔을때 쿠폰 받아지도록
  useEffect(() => {
    const preLoginState = JSON.parse(sessionStorage.getItem('preLoginState'));
    const preLoginStateKey = preLoginState && Object.keys(preLoginState)[0];
    const preLoginStateValue = preLoginState && Object.values(preLoginState)[0];

    if (
      preLoginState &&
      preLoginStateKey === 'isStoreDetailCoupon' &&
      preLoginStateValue === id
    ) {
      mutateAsync(preLoginStateValue)
        .then((res) => {
          if (res.message === 'data inserted') {
            showToast('쿠폰 발급이 완료되었습니다.');
          } else {
            showToast('이미 보유 중인 쿠폰입니다.');
          }
        })
        .catch(() => {
          showToast('쿠폰을 불러오는데 실패했습니다.');
        });

      const loginStateTimeOut = setTimeout(() => {
        sessionStorage.removeItem('preLoginState');
        clearTimeout(loginStateTimeOut);
      }, 2000);
    }
  }, []);

  const handleClick = () => {
    if (isDragging) return;
    onClick();
  };

  const handleDownload = (e) => {
    if (isDragging) return;
    onDownload(e);
  };

  return (
    <Wrapper
      role="button"
      onClick={handleClick}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
    >
      <ContentsBox>
        <Icon
          color={isLogin && isDownload ? '#9FA4AA' : '#EB5757'}
          name="coupon-24_2"
        />
        <Span isDownload={isLogin && isDownload}>
          {discountRate
            ? `${discountRate}% `
            : `${Number(discountPrice).toLocaleString()}원 `}
          할인
        </Span>

        <Title isDownload={isLogin && isDownload}>
          {(itemName ?? productName)?.replace('[★]', '')}
        </Title>
      </ContentsBox>

      <ButtonBox>
        <Shape alt="shape" src={shape} />
        <Button
          className="get-coupon"
          onClick={handleDownload}
          // disabled={mine === 'on'}
        >
          {isLogin && isDownload ? (
            <Icon color="#EB5757" name="check-24_1" size={36} />
          ) : (
            <Icon color="#26282B" name="download-24_1" size={36} />
          )}
        </Button>
      </ButtonBox>
    </Wrapper>
  );
};
const Span = styled.span`
  white-space: nowrap;
  color: ${({ isDownload }) =>
    isDownload
      ? 'var(--new-gray-gray-5, #9FA4AA)'
      : 'var(--new-red-red-8, #eb5757)'};
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.3px;
  padding-right: 4px;
`;

const Title = styled.h3`
  color: ${({ isDownload }) =>
    isDownload
      ? 'var(--new-gray-gray-5, #9FA4AA)'
      : 'var(--new-gray-gray-11, #26282b)'};
  white-space: nowrap;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.3px;
`;

const ContentsBox = styled.div`
  display: flex;
  justify-content: flex-start;
  overflow: hidden;
  width: 292px;

  h3 {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const ButtonBox = styled.div`
  display: flex;
  position: relative;
  width: 50px;
`;

const Shape = styled.img`
  height: 4px;
  width: 109px;
  transform: rotate(90deg);
  position: absolute;
  right: 1px;
`;

const Button = styled.button.attrs({ type: 'button' })`
  background-color: white;
  display: flex;
  height: 100%;
`;

const Wrapper = styled.article`
  cursor: pointer;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 12px;
  background: #fff;
  box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.13);
  padding: 10px;
  overflow: hidden;
  min-width: 310px;

  & * {
    box-sizing: inherit;
  }
`;

export default DiscountCoupon;
