import TagManager from '@sooro-io/react-gtm-module';
import {
  BUILD_TYPE_DEVELOPMENT,
  BUILD_TYPE_PRODUCTION,
  BUILD_TYPE_STAGING,
  getBuildType,
} from 'script/system/build-configs';
import { getGtmTokens } from 'script/system/system-configs';

const initializeGtm = () => {
  const buildType = getBuildType();
  if (
    buildType === BUILD_TYPE_PRODUCTION ||
    buildType === BUILD_TYPE_STAGING ||
    buildType === BUILD_TYPE_DEVELOPMENT
  ) {
    const gtmTokens = getGtmTokens();
    if (gtmTokens) {
      TagManager.initialize({
        auth: gtmTokens.gtmAuth,
        gtmId: gtmTokens.gtmId,
        preview: gtmTokens.gtmPreview,
      });
    }
  }
};

export const initializeApp = () => {
  initializeGtm();
};
