import { Box } from 'components/atoms';
import { useCallback, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';

const FixedCover = ({ children, noOverflow, onClose = () => {}, visible }) => {
  const onClick = useCallback(
    (e) => {
      e.stopPropagation();
      onClose(e);
    },
    [onClose]
  );

  useEffect(() => {
    if (visible) {
      const handleClose = (e) => {
        if (e.keyCode === 27) {
          onClose();
        }
      };

      const body = document.querySelector('body');

      if (!noOverflow) {
        body.style.overflow = 'hidden';
      }
      body.addEventListener('keydown', handleClose);

      return () => {
        body.style.overflow = '';
        body.removeEventListener('keydown', handleClose);
      };
    }
  }, [visible, onClose]);

  return (
    <Wrapper onClick={onClick}>
      <Box
        display="block"
        height="100%"
        maxWidth="420px"
        mx="auto"
        position="relative"
      >
        {children}
      </Box>
    </Wrapper>
  );
};

const smooth = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const Wrapper = styled.div`
  animation: ${smooth} 0.3s ease;
  background-color: #26282b4d;
  bottom: 0;
  box-sizing: border-box;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 10000;

  & * {
    box-sizing: inherit;
  }
`;

export default FixedCover;
