import Icon from 'components/Icons/v2/Icon';
import Slide from 'components/new/Slide';
import styled from 'styled-components';

import InquirySlideContents from './InquirySlideContents';

const InquirySlide = ({ onClose, open, storeCode }) => {
  return (
    <Slide open={open} hideButton onClose={() => onClose()}>
      <TitleBox>
        <TitleTypo>상품 문의</TitleTypo>
        <Icon
          color="#464C53"
          name="close-24_2"
          style={{ cursor: 'pointer' }}
          onClick={() => onClose()}
        />
      </TitleBox>
      <InquirySlideContents storeCode={storeCode} />
    </Slide>
  );
};

const TitleTypo = styled.div`
  overflow: hidden;
  color: var(--new-gray-gray-11, #26282b);
  text-overflow: ellipsis;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: -0.3px;
`;

const TitleBox = styled.div`
  padding: 32px 16px 16px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export default InquirySlide;
