import * as content from 'api/content';
import * as review from 'api/review';
import { handleActions } from 'redux-actions';

import createApiRequest from '../_support';

const POST = 'pirates-data.content/POST';
const REVIEWS = 'pirates-data.content/REVIEWS';
const REVIEWS_SELECTED = `${REVIEWS}.SELECTED`;
const REVIEWS_MORE = `${REVIEWS}.MORE`;
const RELATED_CONTENTS = 'pirates-data.content/RELATED_CONTENTS';
const RELATED_PRICES = 'pirates-data.content/RELATED_PRICES';
const RELATED_PRODUCTS = 'pirates-data.content/RELATED_PRODUCTS';
const ADD_REVIEW = `${REVIEWS}.ADD`;
const MODIFY_REVIEW = `${REVIEWS}.MODIFY`;
const DELETE_REVIEW = `${REVIEWS}.DELETE`;

const [get_post, POST_OK, POST_FAIL] = createApiRequest(
  POST,
  content.get_content
);
const [get_reviews, REVIEWS_OK, REVIEWS_FAIL] = createApiRequest(
  REVIEWS,
  review.get_content_reviews
);
const [get_reviews_selected, REVIEWS_SELECTED_OK, REVIEWS_SELECTED_FAIL] =
  createApiRequest(REVIEWS_SELECTED, review.get_content_reviews);
const [get_reviews_more, REVIEWS_MORE_OK, REVIEWS_MORE_FAIL] = createApiRequest(
  REVIEWS_MORE,
  review.get_content_reviews
);
const [get_related_contents, RELATED_CONTENTS_OK, RELATED_CONTENTS_FAIL] =
  createApiRequest(RELATED_CONTENTS, content.get_related_contents);
const [get_related_prices, RELATED_PRICES_OK, RELATED_PRICES_FAIL] =
  createApiRequest(RELATED_PRICES, content.get_related_prices);
const [get_related_products, RELATED_PRODUCTS_OK, RELATED_PRODUCTS_FAIL] =
  createApiRequest(RELATED_PRODUCTS, content.get_related_products);
const [add_review, ADD_REVIEW_OK, ADD_REVIEW_FAIL] = createApiRequest(
  ADD_REVIEW,
  review.add_review
);
const [modify_review, MODIFY_REVIEW_OK, MODIFY_REVIEW_FAIL] = createApiRequest(
  MODIFY_REVIEW,
  review.modify_review
);
const [delete_review, DELETE_REVIEW_OK, DELETE_REVIEW_FAIL] = createApiRequest(
  DELETE_REVIEW,
  review.delete_review
);

const Types = {
  POST,
  RELATED_CONTENTS,
  RELATED_PRICES,
  RELATED_PRODUCTS,
  REVIEWS,
  REVIEWS_MORE,
};

const state = {
  post: {
    article: [],
    statistics: {},
  },
  related: {
    contents: [],
    prices: [],
    products: [],
  },
  reviews: [],
  topMore: true,
};

// compose reducer
const reducer = handleActions(
  {
    [ADD_REVIEW]: (state) => ({ ...state }),
    [ADD_REVIEW_OK]: (state, action) => {
      if (action.payload.data.replies !== null) {
        return {
          ...state,
          reviews: [
            {
              ...action.payload.data,
              attachments: action.payload.data.attachments.map((item) => ({
                code: action.payload.data.id,
                origin: item.origin,
                thumbnail: item.thumbnail,
              })),
              secret: action.payload.data.secretStatusCode,
            },
            ...state.reviews,
          ],
        };
      }
      return {
        ...state,
        reviews: [
          ...state.reviews.map((review) => {
            if (review.id === action.payload.data.parentCode) {
              return {
                ...review,
                replies: [
                  ...review.replies,
                  {
                    ...action.payload.data,
                    attachments: action.payload.data.attachments.map(
                      (item) => ({
                        code: action.payload.data.id,
                        origin: item.origin,
                        thumbnail: item.thumbnail,
                      })
                    ),
                  },
                ],
              };
            }
            return review;
          }),
        ],
      };
    },

    [DELETE_REVIEW]: (state) => ({ ...state }),
    [DELETE_REVIEW_OK]: (state, action) => {
      let [isReview, isReply] = [false, false];
      for (const review of state.reviews) {
        if (isReply) {
          break;
        } else if (review.id === action.payload.data.id) {
          isReview = true;
          break;
        } else {
          for (const reply of review.replies) {
            if (reply.id === action.payload.data.id) {
              isReply = true;
              break;
            }
          }
        }
      }
      if (isReview) {
        return {
          ...state,
          reviews: state.reviews.filter(
            (review) => review.id !== action.payload.data.id
          ),
        };
      }
      return {
        ...state,
        reviews: state.reviews.map((review) => {
          return {
            ...review,
            replies: review.replies.filter(
              (reply) => reply.id !== action.payload.data.id
            ),
          };
        }),
      };
    },

    [MODIFY_REVIEW]: (state) => ({ ...state }),
    [MODIFY_REVIEW_OK]: (state, action) => {
      if (!action.payload.data.parentCode) {
        return {
          ...state,
          reviews: state.reviews.map((review) => {
            if (action.payload.data.id === review.id) {
              return {
                ...review,
                attachments: action.payload.data.attachments.map((item) => ({
                  code: review.id,
                  origin: item.origin,
                  thumbnail: item.thumbnail,
                })),
                comment: action.payload.data.comment,
                rating: action.payload.data.rating,
                secret: action.payload.data.secretStatusCode,
              };
            }
            return review;
          }),
        };
      }
      return {
        ...state,
        reviews: state.reviews.map((review) => {
          if (review.id === action.payload.data.parentCode) {
            return {
              ...review,
              replies: review.replies.map((reply) => {
                if (reply.id === action.payload.data.id) {
                  return {
                    ...reply,
                    attachments: action.payload.data.attachments.map(
                      (item) => ({
                        code: reply.id,
                        origin: item.origin,
                        thumbnail: item.thumbnail,
                      })
                    ),
                    comment: action.payload.data.comment,
                  };
                }
                return reply;
              }),
            };
          }
          return review;
        }),
      };
    },
    [POST]: (state) => ({
      post: {
        article: [],
        statistics: {},
      },
      related: {
        contents: [],
        prices: [],
        products: [],
      },
      reviews: [],
    }),
    [POST_OK]: (state, action) => {
      content.update_content_view({
        targetCode: action.payload.id,
      });
      return {
        ...state,
        post: action.payload,
      };
    },
    [RELATED_CONTENTS]: (state) => ({ ...state }),
    [RELATED_CONTENTS_OK]: (state, action) => ({
      ...state,
      related: {
        ...state.related,
        contents: action.payload,
      },
    }),
    [RELATED_PRICES]: (state) => ({ ...state }),
    [RELATED_PRICES_OK]: (state, action) => ({
      ...state,
      related: {
        ...state.related,
        prices: action.payload,
      },
    }),
    [RELATED_PRODUCTS]: (state) => ({ ...state }),
    [RELATED_PRODUCTS_OK]: (state, action) => ({
      ...state,
      related: {
        ...state.related,
        products: action.payload,
      },
    }),
    [REVIEWS]: (state) => ({ ...state }),
    [REVIEWS_MORE]: (state) => ({ ...state }),
    [REVIEWS_MORE_OK]: (state, action) => ({
      ...state,
      reviews: [...state.reviews, ...action.payload.slice(1)],
    }),
    [REVIEWS_OK]: (state, action) => ({
      ...state,
      reviews: action.payload,
      topMore: false,
    }),
    [REVIEWS_SELECTED]: (state) => ({ ...state }),
    [REVIEWS_SELECTED_OK]: (state, action) => ({
      ...state,
      reviews: action.payload.review,
      topMore: action.payload.topMore,
    }),
  },
  state
);

// export dispatch functions
export {
  add_review,
  delete_review,
  get_post,
  get_related_contents,
  get_related_prices,
  get_related_products,
  get_reviews,
  get_reviews_more,
  get_reviews_selected,
  modify_review,
  Types,
};

// export reducer;
export default reducer;
