import Document from 'components/common/document';
import { HeadNew } from 'components/common/header';
import Icon from 'components/Icons/v2/Icon';
import {
  OrderDelivery,
  OrderInfo,
  OrderProducts,
  OrderStatusInfo,
  PaymentInfo,
  PickUpStore,
  Purchaser,
} from 'components/mypage/myOrder/orderHistoryDetail';
import { Loading } from 'components/views';
import useOrderHistoryDetail from 'hooks/Query/MyPage/useOrderHistoryDetail';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

const OrderHistoryDetail = () => {
  const location = useLocation();
  const orderCode = location.pathname.split('/')[4];
  const { data, isFetching, refetch } = useOrderHistoryDetail(orderCode);

  return (
    <>
      <Document.Head title="주문내역 상세 | 마이페이지" type="single" />
      <HeadNew
        backUrl="/마이페이지/내주문"
        label="수산시장 주문내역 상세"
        rightIcon={<Icon color="#464C53" name="home-24_2" />}
      />
      <Container>
        {isFetching ? (
          <Loading />
        ) : (
          <>
            <OrderStatusInfo
              cancelComment={data.cancelComment}
              delivery={data.delivery}
              etaEndDateTime={data.etaEndDateTime}
              etaStartDateTime={data.etaStartDateTime}
              orderCode={data.publicOrderCode}
              orderType={data.orderType}
              refetch={refetch}
              reservationDateTime={data.reservationDateTime}
              status={data.status}
              storeCode={data.storeCode}
            />
            <Divider />

            <OrderInfo
              name={`${data.marketLabel} ${data.storeLabel}`}
              orderCode={data.publicOrderCode}
              orderDateTime={data.orderDateTime}
            />
            <Divider />

            <Purchaser
              name={data.purchaser.name}
              phone={data.purchaser.phone}
            />
            <Divider />

            {data.orderType === 'CONVENIENCE_STORE' && (
              <>
                <PickUpStore pickupConvenience={data.pickupConvenience} />
                <Divider />
              </>
            )}

            <OrderProducts comment={data.comment} products={data.products} />
            <Divider />

            {data.orderType === 'QUICK' || data.orderType === 'DAY_DELIVERY' ? (
              <>
                <OrderDelivery delivery={data.delivery} />
                <Divider />
              </>
            ) : null}

            <PaymentInfo
              orderCode={data.publicOrderCode}
              payInfo={data.payInfo}
            />
          </>
        )}
      </Container>
    </>
  );
};

const Divider = styled.div`
  height: 10px;
  background: var(--Gray-gray-2, #f3f3f3);
`;

const Container = styled.div`
  margin-top: 56px;
`;

export default OrderHistoryDetail;
