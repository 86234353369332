import { cancelOrderByOrderType } from 'api/order';
import Icon from 'components/Icons/v2/Icon';
import InquirySlide from 'components/mypage/myOrder/inquirySlide/InquirySlide';
import OrderCancelConfirmModal from 'components/mypage/myOrder/OrderCancelConfirmModal';
import { Loading } from 'components/views';
import { conf } from 'conf/settings';
import { ToastContext } from 'context/toast';
import dayjs from 'dayjs';
import { useContext, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import {
  getOrderStatus,
  getOrderType,
  getPayType,
  getReservationDateTimeFormat,
  moneyFormatter,
} from 'utils';

const OrderHistory = ({ history, refetch }) => {
  const location = useLocation();
  const { showToast } = useContext(ToastContext);
  const [isOpenInquirySlide, setIsOpenInquirySlide] = useState(false);
  const [isOpenCancelModal, setIsOpenCancelModal] = useState(false);
  const [isCancelLoading, setIsCancelLoading] = useState(false);

  const {
    deliveryDuration,
    etaEndDateTime,
    etaStartDateTime,
    marketLabel,
    orderCode,
    orderEntryNum,
    orderType,
    payType,
    productName,
    productPriceName,
    requestDateTime,
    reservationDateTime,
    status,
    storeCode,
    storeLabel,
    totalPrice,
  } = history;

  const isViableReservationDateTime =
    status !== 'SENT' &&
    status !== 'PURCHASE_CONFIRMATION' &&
    status !== 'CANCEL' &&
    status !== 'CANCEL_AUTO' &&
    status !== 'CANCEL_BY_ADMIN' &&
    status !== 'CANCEL_ADMIN';

  const productNamePares = `${productName}(${productPriceName})${
    orderEntryNum > 1 ? ` 외 ${orderEntryNum - 1}건` : ''
  }`;

  const isCanOrderCancelStatus = status === 'REQUEST';
  const isCanWriteReviewStatus =
    status === 'SENT' || status === 'PURCHASE_CONFIRMATION';

  const handleClickWriteReview = () => {
    window.location.href = `${conf.FISH_MARKET_URL}/${history.permalink}/리뷰`;
  };

  const handleClickCancelOrder = () => {
    setIsCancelLoading(true);

    cancelOrderByOrderType({ orderCode, orderType })
      .then(() => {
        refetch();
        showToast('주문취소가 완료되었습니다.');
      })
      .catch(() => showToast('잠시후 다시 시도해주세요.'))
      .finally(() => setIsCancelLoading(false));
  };

  return isCancelLoading ? (
    <Loading />
  ) : (
    <>
      <Container>
        <HeaderBox>
          <DateTypo>
            {dayjs(new Date(requestDateTime)).format('YYYY.MM.DD(ddd) HH:mm')}
          </DateTypo>

          <Link to={`${location.pathname}/detail/${orderCode}`}>
            <OrderDetailBox>
              <OrderDetail>주문 상세</OrderDetail>
              <Icon color="#2F80ED" name="arrow_right-16_1" size={16} />
            </OrderDetailBox>
          </Link>
        </HeaderBox>

        <InfoBox>
          <InfoTypoBox>
            <InfoTypoTitle>주문번호</InfoTypoTitle>
            <InfoTypoText>{orderCode}</InfoTypoText>
          </InfoTypoBox>

          {orderType && (
            <InfoTypoBox>
              <InfoTypoTitle>주문유형</InfoTypoTitle>
              <InfoTypoText>{getOrderType(orderType)}</InfoTypoText>
            </InfoTypoBox>
          )}

          <InfoTypoBox>
            <InfoTypoTitle>주문상태</InfoTypoTitle>
            <InfoTypoColBox
              isViableReservationDateTime={isViableReservationDateTime}
            >
              <InfoTypoText>{getOrderStatus(status, orderType)}</InfoTypoText>
              {isViableReservationDateTime && (
                <InfoTypoText>
                  {getReservationDateTimeFormat({
                    deliveryDuration,
                    etaEndDateTime,
                    etaStartDateTime,
                    isVisibleTitle: true,
                    orderType,
                    reservationDateTime,
                  })}
                </InfoTypoText>
              )}
            </InfoTypoColBox>
          </InfoTypoBox>

          <InfoTypoBox>
            <InfoTypoTitle>가게명</InfoTypoTitle>
            <InfoTypoText>
              {marketLabel} {storeLabel}
            </InfoTypoText>
          </InfoTypoBox>

          <InfoTypoBox>
            <InfoTypoTitle>주문메뉴</InfoTypoTitle>
            <InfoTypoText>{productNamePares}</InfoTypoText>
          </InfoTypoBox>

          {payType && (
            <InfoTypoBox>
              <InfoTypoTitle>결제수단</InfoTypoTitle>
              <InfoTypoText>{getPayType(payType)}</InfoTypoText>
            </InfoTypoBox>
          )}

          <InfoTypoBox>
            <InfoTypoTitle>결제정보</InfoTypoTitle>
            <InfoTypoText>{moneyFormatter(totalPrice)}원</InfoTypoText>
          </InfoTypoBox>
        </InfoBox>

        <ButtonBox>
          {isCanOrderCancelStatus && (
            <StyledButton onClick={() => setIsOpenCancelModal(true)}>
              주문 취소
            </StyledButton>
          )}
          <StyledButton onClick={() => setIsOpenInquirySlide(true)}>
            상품 문의
          </StyledButton>
          {isCanWriteReviewStatus && (
            <StyledButton isBlackStyle onClick={() => handleClickWriteReview()}>
              후기 작성
            </StyledButton>
          )}
        </ButtonBox>
      </Container>
      <Divider />

      <OrderCancelConfirmModal
        isOpen={isOpenCancelModal}
        onClose={() => setIsOpenCancelModal(false)}
        onSuccess={() => handleClickCancelOrder()}
      />

      <InquirySlide
        open={isOpenInquirySlide}
        storeCode={storeCode}
        onClose={() => setIsOpenInquirySlide(false)}
      />
    </>
  );
};

export default OrderHistory;

const Divider = styled.div`
  height: 10px;
  background: var(--Gray-gray-2, #f3f3f3);
`;

const StyledButton = styled.div`
  cursor: pointer;
  width: 100%;
  padding: 10px 16px;
  text-align: center;
  border: ${({ isBlackStyle }) =>
    isBlackStyle ? 'none' : '1px solid var(--new-gray-gray-2, #e8ebed)'};
  border-radius: 4px;
  color: ${({ isBlackStyle }) => (isBlackStyle ? 'white' : 'black')};
  background-color: ${({ isBlackStyle }) =>
    isBlackStyle ? 'var(--new-gray-gray-10, #383B3E)' : 'white'};
`;

const ButtonBox = styled.div`
  display: flex;
  gap: 8px;
`;

const InfoTypoColBox = styled.div`
  display: flex;
  flex-direction: column;
  height: ${({ isViableReservationDateTime }) =>
    isViableReservationDateTime ? '44px' : '22px'};
`;

const InfoTypoText = styled.div`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  flex: 1 0 0;
  overflow: hidden;
  color: var(--Gray-gray-11, #26282b);
  text-overflow: ellipsis;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.3px;
`;

const InfoTypoTitle = styled.div`
  display: -webkit-box;
  width: 60px;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  color: var(--Gray-gray-6, #8a8d90);
  text-overflow: ellipsis;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.3px;
`;

const InfoTypoBox = styled.div`
  display: flex;
  gap: 12px;
`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 16px 0 24px 0;
`;

const OrderDetail = styled.div`
  color: var(--new-blue-blue-9, #2f80ed);
  font-family: 'Noto Sans KR';
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.3px;
`;

const OrderDetailBox = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
  cursor: pointer;
`;

const DateTypo = styled.div`
  overflow: hidden;
  color: var(--Gray-gray-11, #26282b);
  text-overflow: ellipsis;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.3px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
`;

const HeaderBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 16px;
  border-bottom: 1px solid var(--new-gray-gray-2, #f3f3f3);
`;

const Container = styled.div`
  padding: 24px 16px;
  display: flex;
  flex-direction: column;
`;
