import './style.css';

import { GridView, Image } from 'components/views';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const GalleryItem = React.memo(({ item }) => {
  const location = useLocation();
  const pageTitle = location.pathname.slice(1);

  const handleClickCard = () => {
    if (location.pathname === '/홈' || location.pathname === '/') {
      sessionStorage.setItem('홈-scrollY', window.scrollY);
    }
    if (location.pathname === '/후기') {
      sessionStorage.setItem('후기-scrollY', window.scrollY);
    }
  };
  const uriArr = item.uri.split('/');
  const removedTargetIdUri = uriArr.slice(0, uriArr.length - 1).join('/');
  const targetId = uriArr.pop();

  return (
    <div className="card-template type6" onClick={handleClickCard}>
      <div className="inner-section type1">
        <div className="img-block">
          <Image
            className="display-block"
            height={136}
            resizeWidth="260"
            src={item.thumbnail}
            width={136}
          />
        </div>
        <Link
          aria-label="review-link"
          to={{
            pathname: removedTargetIdUri,
            search: `?target=${targetId}`,
            state: pageTitle === '' ? '홈' : pageTitle,
          }}
        >
          <span className="bg" />
        </Link>
      </div>
      <div className="inner-section type2" style={{ pointerEvents: 'none' }}>
        <span className="rating">
          <i className="fas fa-star" style={{ color: '#ffffff' }} />
          {item.rating}
        </span>
        <span className="name type2 txt-ellipsis">{item.label}</span>
      </div>
    </div>
  );
});

const GalleryView = ({ items }) => {
  return (
    <GridView
      items={items}
      render={(item) => <GalleryItem key={item.id} item={item} />}
    />
  );
};

export default GalleryView;
