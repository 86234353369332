import * as content_api from 'api/content';
import * as home_api from 'api/home';
import { handleActions } from 'redux-actions';

import createApiRequest from './_support';
import { merge_more_list } from './price';

const SEARCH_PRICE = 'pirates-data.home/SEARCH_PRICE';
const SEARCH_COUPON = 'pirates-data.home/SEARCH_COUPON';
const SEARCH_SHOPPING = 'pirates-data.home/SEARCH_SHOPPING';
const SEARCH_DELICIOUS = 'pirates-data.home/SEARCH_DELICIOUS';
const SEARCH_CONTENTS = 'pirates-data.home/SEARCH_CONTENTS';

const SEARCH_PRICE_MORE = 'pirates-data.home/SEARCH_PRICE_MORE';
const SEARCH_COUPON_MORE = 'pirates-data.home/SEARCH_COUPON_MORE';
const SEARCH_SHOPPING_MORE = 'pirates-data.home/SEARCH_SHOPPING_MORE';
const SEARCH_DELICIOUS_MORE = 'pirates-data.home/SEARCH_DELICIOUS_MORE';
const SEARCH_CONTENTS_MORE = 'pirates-data.home/SEARCH_CONTENTS_MORE';
const SAVE_ITEM = 'pirates-data.home/SAVE_ITEM';

const INIT_SEARCH = 'pirates-data.home/SEARCH_INIT';
const TOGGLE_CONTENTS_LIKE = `${SEARCH_CONTENTS}_LIKE`;

const [search_price, SEARCH_PRICE_OK, SEARCH_PRICE_FAIL] = createApiRequest(
  SEARCH_PRICE,
  home_api.search_home_price
);
const [search_coupon, SEARCH_COUPON_OK, SEARCH_COUPON_FAIL] = createApiRequest(
  SEARCH_COUPON,
  home_api.search_home_coupon
);
const [search_shopping, SEARCH_SHOPPING_OK, SEARCH_SHOPPING_FAIL] =
  createApiRequest(SEARCH_SHOPPING, home_api.search_home_shopping);
// const [search_market, SEARCH_MARKET_OK, SEARCH_MARKET_FAIL] = createApiRequest(
//   SEARCH_MARKET,
//   home_api.search_home_market
// );
const [search_delicious, SEARCH_DELICIOUS_OK, SEARCH_DELICIUOS_FAIL] =
  createApiRequest(SEARCH_DELICIOUS, home_api.search_home_delicious);
const [search_contents, SEARCH_CONTENTS_OK, SEARCH_CONTENTS_FAIL] =
  createApiRequest(SEARCH_CONTENTS, home_api.search_home_contents);

const [search_price_more, SEARCH_PRICE_MORE_OK, SEARCH_PRICE_MORE_FAIL] =
  createApiRequest(SEARCH_PRICE_MORE, home_api.search_home_price);
const [search_coupon_more, SEARCH_COUPON_MORE_OK, SEARCH_COUPON_MORE_FAIL] =
  createApiRequest(SEARCH_COUPON_MORE, home_api.search_home_coupon);
const [
  search_shopping_more,
  SEARCH_SHOPPING_MORE_OK,
  SEARCH_SHOPPING_MORE_FAIL,
] = createApiRequest(SEARCH_SHOPPING_MORE, home_api.search_home_shopping);
// const [search_market_more, SEARCH_MARKET_MORE_OK, SEARCH_MARKET_MORE_FAIL] =
//   createApiRequest(SEARCH_MARKET_MORE, home_api.search_home_market);
const [
  search_delicious_more,
  SEARCH_DELICIOUS_MORE_OK,
  SEARCH_DELICIUOS_MORE_FAIL,
] = createApiRequest(SEARCH_DELICIOUS_MORE, home_api.search_home_delicious);
const [
  search_contents_more,
  SEARCH_CONTENTS_MORE_OK,
  SEARCH_CONTENTS_MORE_FAIL,
] = createApiRequest(SEARCH_CONTENTS_MORE, home_api.search_home_contents);

const [init_search, INIT_SEARCH_OK, INIT_SEARCH_FAIL] = createApiRequest(
  INIT_SEARCH,
  () => {
    return { data: null };
  }
);
const [
  toggle_contents_like,
  TOGGLE_CONTENTS_LIKE_OK,
  TOGGLE_CONTENTS_LIKE_FAIL,
] = createApiRequest(TOGGLE_CONTENTS_LIKE, content_api.toggle_content_like);
const [save_item, SAVE_ITEM_OK, SAVE_ITEM_FAIL] = createApiRequest(
  SAVE_ITEM,
  (item) => ({ data: item })
);

const initState = {
  contents: [],
  coupons: [],
  delicious: [],
  item: null,
  next: {
    page: null,
  },
  prices: [],
  query: {
    item: { code: '0000000000', label: '추천 품목' },
    query: {},
    region: { code: '0000000000', label: '모든 지역' },
  },
  retails: [],
  stores: [],
};

const home_search = handleActions(
  {
    [INIT_SEARCH]: (state) => ({ ...state }),
    [INIT_SEARCH_OK]: (state, action) => ({
      ...initState,
    }),
    [SAVE_ITEM]: (state) => ({ ...state }),
    [SAVE_ITEM_OK]: (state, action) => ({
      ...state,
      item: action.payload,
    }),
    [SEARCH_CONTENTS]: (state) => ({ ...state }),
    [SEARCH_CONTENTS_MORE]: (state) => ({ ...state }),
    [SEARCH_CONTENTS_MORE_OK]: (state, action) => ({
      ...state,
      contents: [...state.contents, ...action.payload],
    }),
    [SEARCH_CONTENTS_OK]: (state, action) => ({
      ...state,
      contents: action.payload,
    }),
    [SEARCH_COUPON]: (state) => ({ ...state }),
    [SEARCH_COUPON_MORE]: (state) => ({ ...state }),
    [SEARCH_COUPON_MORE_OK]: (state, action) => ({
      ...state,
      coupons: [...state.coupons, ...action.payload],
    }),
    [SEARCH_COUPON_OK]: (state, action) => ({
      ...state,
      coupons: action.payload.coupons,
    }),
    [SEARCH_DELICIOUS]: (state) => ({ ...state }),
    [SEARCH_DELICIOUS_MORE]: (state) => ({ ...state }),

    [SEARCH_DELICIOUS_MORE_OK]: (state, action) => ({
      ...state,
      delicious: [...state.delicious, ...action.payload],
    }),
    [SEARCH_DELICIOUS_OK]: (state, action) => ({
      ...state,
      delicious: action.payload,
    }),
    // [SEARCH_MARKET]: (state) => ({ ...state }),
    // [SEARCH_MARKET_MORE]: (state) => ({ ...state }),
    // [SEARCH_MARKET_MORE_OK]: (state, action) => ({
    //   ...state,
    //   stores: [...state.stores, ...action.payload],
    // }),
    // [SEARCH_MARKET_OK]: (state, action) => ({
    //   ...state,
    //   stores: action.payload,
    // }),
    [SEARCH_PRICE]: (state) => ({ ...state }),
    [SEARCH_PRICE_MORE]: (state) => ({ ...state }),
    [SEARCH_PRICE_MORE_OK]: (state, action) => ({
      ...state,
      next: {
        page: action.payload.nextPageNo,
      },
      prices: merge_more_list(state.prices, action.payload.list),
    }),
    [SEARCH_PRICE_OK]: (state, action) => ({
      ...state,
      next: {
        page: action.payload.nextPageNo,
      },
      prices: action.payload.list,
    }),
    [SEARCH_SHOPPING]: (state) => ({ ...state }),
    [SEARCH_SHOPPING_MORE]: (state) => ({ ...state }),
    [SEARCH_SHOPPING_MORE_OK]: (state, action) => ({
      ...state,
      retails: [...state.retails, ...action.payload],
    }),
    [SEARCH_SHOPPING_OK]: (state, action) => ({
      ...state,
      retails: action.payload,
    }),

    [TOGGLE_CONTENTS_LIKE]: (state) => ({ ...state }),
    [TOGGLE_CONTENTS_LIKE_OK]: (state, action) => ({
      ...state,
      contents: [
        ...state.contents.map((content) => {
          if (content.id === action.payload.targetCode) {
            return {
              ...content,
              favorite: action.payload.doLike ? 'on' : 'off',
            };
          }
          return content;
        }),
      ],
    }),
  },
  initState
);

export {
  init_search,
  save_item,
  search_contents,
  search_contents_more,
  search_coupon,
  search_coupon_more,
  search_delicious,
  search_delicious_more,
  // search_market,
  // search_market_more,
  search_price,
  search_price_more,
  search_shopping,
  search_shopping_more,
  toggle_contents_like,
};

export default home_search;
